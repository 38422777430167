import { render, staticRenderFns } from "./casePagination38.vue?vue&type=template&id=a8eb1ab2&scoped=true"
import script from "./casePagination38.vue?vue&type=script&lang=js"
export * from "./casePagination38.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8eb1ab2",
  null
  
)

export default component.exports