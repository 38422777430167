import { render, staticRenderFns } from "./inTrans9.vue?vue&type=template&id=6348308e&scoped=true"
import script from "./inTrans9.vue?vue&type=script&lang=js"
export * from "./inTrans9.vue?vue&type=script&lang=js"
import style0 from "./inTrans9.vue?vue&type=style&index=0&id=6348308e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6348308e",
  null
  
)

export default component.exports