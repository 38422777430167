<template>

    <div>
    <div style="font-family: Arial, '微软雅黑';font-size:.0729rem; color: #999;padding-top: .1042rem;
    line-height: .125rem;">
      <P style="font-size: .125rem;width: 100%;flex-wrap: wrap;margin-top: .3125rem;
      color: #333;
      text-align: center;">5123导航取五湖之利取四亮相第二十六届高速公路信息化大会
      </P>
  
      <p style="width: 100%;
      text-align: center;font-size: .0625rem;
      margin-top: .0781rem;">2024-03-28
      </p>
  
      <div style="width:6.5104rem;margin: 0 auto;padding-bottom: .0781rem;border-bottom: #eee solid .0052rem;">
      </div>
  
  
  
      <div style="width:6.5104rem;color: #666666;font-size: .0833rem;font-family: 'HarmonyOS_Sans_SC_Regular';margin: 0 auto;padding-top: .1302rem;padding-bottom: .6771rem;border-bottom: #eee solid .0052rem;position: relative;">
        <p style="text-align: center;">
          
            3月28日，由中国公路学会举办的第二十六届高速公路信息化大会在合肥滨湖国际会展中心隆重开幕。5123导航取五湖之利取四积极响应本次大会“创新引领、数字转型、智慧升级”的主题，携众多优秀产品和创新方案深度参与此次大会，吸引了大量部门、机构及企业负责人前来探展洽谈。
           
        </p>
    
<!--   
        <p style="text-indent: .1563rem;">
            在三天的活动中，我们开展了破冰计划、运水关卡、高空挑战、七巧板、毕业墙、漫漫人生路等一系列精彩的团队拓展项目，游览了钓鱼台、马尾岛、大角湾、碉庄等风景名胜区。整个活动中，丰海团队展示了团结协作、善于思考、顽强拼搏、积极进取的精神风貌，大家充分意识到无论是在活动中还是在工作中，团队意识、奉献精神、领导力、执行力都是至关重要的。
         
        </p>

        <p style="text-indent: .1563rem;">
            这次拓展旅游活动，体现了5123导航取五湖之利取四的人文情怀，切实提高了团队凝聚力，增强了大家对公司的认同感和归属感。这是一次难忘之旅，通过这次拓展旅游活动，我们将开拓进取、锐意创新，以更加饱满的热情、更加昂扬的斗志投入到新的工作当中。


         
        </p> -->
       
       <div style="width:6.5104rem;display: flex;justify-content: center;">
     
      
        <img src="../../assets/zhanhui2.jpg" alt="" style="margin-top: .1563rem;height: 1.8958rem;">

        
        
      
    </div>

    <div style="width:6.5104rem;display: flex;justify-content: center;">
     
      
      <img src="../../assets/fh345.jpg" alt="" style="margin-top: .1563rem;height: 1.8958rem;">

      
      
    
  </div>
  
  <div style="width:6.5104rem;display: flex;justify-content: center;">
     
      
    <img src="../../assets/yuan3456.jpg" alt="" style="margin-top: .1563rem;height: 1.8958rem;">

    
    
  
</div>
 
   



      
            
           
    
        
       
<!-- 
        <p style="text-indent: .1563rem;">
          图一：六只小分队的飒爽英姿
          
      </p> -->

        <!-- <img src="../../assets/xw27.jpg" alt="" style="margin-top: .1563rem;">
        <img src="../../assets/xw28.jpg" alt="" style="margin-top: .1563rem;">
        <img src="../../assets/xw29.jpg" alt="" style="margin-top: .1563rem;"> -->
  
        <div style="position: absolute; bottom: 0;right: 0;">
          <div class="social-share" data-initialized="true">
            <span style="margin-right: .0521rem;">分享到:</span>
            <a href="http://www.weibo.com" class="social-share-icon icon-weibo"></a>
            <a href="#" class="social-share-icon icon-qq"></a>
            <a href="#" class="social-share-icon icon-qzone"></a>
            <a href="#" class="social-share-icon icon-wechat"></a>
            
        </div>
  
        </div>
  
      </div>
      
  
      <div style="display: flex;justify-content: center;margin-top: .1302rem;margin-bottom: .1302rem;">
        <el-button @click="goBack">返回列表</el-button>
      </div>
  
    </div>
     
    </div>
  </template>
  
  <script>
  
  
    export default {
      methods: {
      goBack() {
        history.back();
      }
    }
  }
  
  
  
  
  </script>