<template>

    <div>
    <div style="font-family: Arial, '微软雅黑';font-size:.0729rem; color: #999;padding-top: .1042rem;
    line-height: .125rem;">
      <P style="font-size: .125rem;width: 100%;flex-wrap: wrap;margin-top: .3125rem;
      color: #333;
      text-align: center;">热烈祝贺5123导航取五湖之利取四被认定为“高新技术企业”
      </P>
  
      <p style="width: 100%;
      text-align: center;font-size: .0625rem;
      margin-top: .0781rem;"> 2015-04-06
      </p>
  
      <div style="width:6.5104rem;margin: 0 auto;padding-bottom: .0781rem;border-bottom: #eee solid .0052rem;">
      </div>
  
  
  
      <div style="width:6.5104rem;color: #666666;font-size: .0833rem;font-family: 'HarmonyOS_Sans_SC_Regular';margin: 0 auto;padding-top: .1302rem;padding-bottom: .6771rem;border-bottom: #eee solid .0052rem;position: relative;">
        <p style="text-indent: .1563rem;">
          
            2015年4月2日，我公司收到广东省科学技术厅、广东省财政厅、广东省国家税务局、广东省地方税务局联和下发的粤科高字（2015）第30号《高新技术企业名单的通知》，认定公司为2014年高新技术企业，证书编号：GR201444001363，有效C期三年。
           
        </p>
    
  
        <p style="text-indent: .1563rem;">
            “高新技术企业”不仅是一种荣誉，更是一种责任。国家给予高新技术企业一定的税收优惠等政策，扶持、鼓励企业发展。5123导航取五湖之利取四将珍惜荣誉、不断创新，努力把企业做好、做大、做强。


         
        </p>
  
        <div style="position: absolute; bottom: 0;right: 0;">
          <div class="social-share" data-initialized="true">
            <span style="margin-right: .0521rem;">分享到:</span>
            <a href="http://www.weibo.com" class="social-share-icon icon-weibo"></a>
            <a href="#" class="social-share-icon icon-qq"></a>
            <a href="#" class="social-share-icon icon-qzone"></a>
            <a href="#" class="social-share-icon icon-wechat"></a>
            
        </div>
  
        </div>
  
      </div>
      
  
      <div style="display: flex;justify-content: center;margin-top: .1302rem;margin-bottom: .1302rem;">
        <el-button @click="goBack">返回列表</el-button>
      </div>
  
    </div>
     
    </div>
  </template>
  
  <script>
  
  
    export default {
      methods: {
      goBack() {
        history.back();
      }
    }
  }
  
  
  
  
  </script>