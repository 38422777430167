<template>
    <div>

      
    
        <div
            style="width: 100%; height: .6823rem; display: flex; justify-content: center; align-items: center; position: relative;">
            <img style="margin: 0 auto;position: absolute;height: .1875rem;" src="../../assets/智慧高速.png" alt="" >
            <p
                style="font-size: .1667rem; color: #5B5B5B; font-family: PingFang SC, PingFang SC; font-weight: bold; position: absolute; bottom: .0938rem;">
                智慧高速</p>
            <p
                style=" display: inline-block; height: .0104rem; width: .3333rem;background-color:  #407BF5;;font-size: .1667rem; color: #5B5B5B; font-family: PingFang SC, PingFang SC; font-weight: bold; position: absolute; bottom: .0417rem;">
            </p>
        </div>

        <div style="width: 6.5104rem;margin:0 auto;transform: translateY(-0.1563rem) translateX(-0.1042rem);">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item :to="{ path: '/SoluTion'}">解决方案</el-breadcrumb-item>
              <el-breadcrumb-item :to="{ path: '/samCov18'}">智慧高速</el-breadcrumb-item>
            </el-breadcrumb>
          </div>

        <div style="width: 100%;margin-top: .0521rem; display: flex; justify-content: center; align-items: center;margin-top: .1563rem;">
            <div @click="handleClick" @mouseover="chaImg" @mouseout="chaoutImg"
                style="width: 1.6146rem; height: 1.474rem; background-color: #786f6f;position: relative; overflow: hidden;">

                <img src="../../assets/501.webp" alt=""
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                <p
                    style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%);opacity: 1;width: 100%; height: .2083rem; position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;">
                    智能防疲劳安全预警系统</p>
            </div>



            <div @click="handleClick1" @mouseover="chaImg" @mouseout="chaoutImg"
                style="width: 1.6146rem; height: 1.474rem; background-color: #5b4b4b; margin-left: .1354rem;position: relative;overflow: hidden;">
                <img src="../../assets/502.webp" alt="" style="float: left;">
                <p
                    style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%);opacity: 0.8;width: 100%; height: .2083rem; position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;">
                    行人摩托车闯入智能预警系统</p>
            </div>
            <div @click="handleClick2" @mouseover="chaImg" @mouseout="chaoutImg"
                style="width: 1.6146rem; height: 1.474rem; background-color: #7e7878; margin-left: .1354rem;position: relative; overflow: hidden;">
                <img src="../../assets/503.webp" alt=""
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                <p style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%);opacity: 0.8;
  width: 100%; height: .2083rem; position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem;
   font-weight: 400;padding-left: .0521rem;box-sizing: border-box;">雾天公路行车安全诱导系统</p>
            </div>
            <div @click="handleClick3" @mouseover="chaImg" @mouseout="chaoutImg"
                style="overflow: hidden;width: 1.6146rem; height: 1.474rem; background-color: #1e1a1a; margin-left: .1354rem;position: relative;">
                <img src="../../assets/504.webp" alt=""
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                <p style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%);opacity: 0.8;
  width: 100%; height: .2083rem; position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem;
   font-weight: 400;padding-left: .0521rem;white-space: normal;box-sizing: border-box;">低位照明—路灯“隐身术”，舒适道路</p>
            </div>
        </div>

        <div style="width: 100%; margin-top: .0781rem;  display: flex; justify-content: center; align-items: center;">
            <div @click="handleClick4" @mouseover="chaImg" @mouseout="chaoutImg"
                style="overflow: hidden;width: 1.6146rem; height: 1.474rem; background-color: #786f6f;position: relative;">
                <img src="../../assets/509.webp" alt=""
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                <p style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%);opacity: 0.8;
  width: 100%; height: .2083rem; position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem;
   font-weight: 400;padding-left: .0521rem;box-sizing: border-box;">隧道紧急停车带预警系统</p>
            </div>
            <div @click="handleClick5" @mouseover="chaImg" @mouseout="chaoutImg"
                style="overflow: hidden;width: 1.6146rem; height: 1.474rem; background-color: #5b4b4b; margin-left: .1354rem;position: relative;">
                <img src="../../assets/510.webp" alt=""
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                <p style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%);opacity: 0.8;
  width: 100%; height: .2083rem; position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem;
   font-weight: 400;padding-left: .0521rem;box-sizing: border-box;">高速隧道团雾解决方案</p>
            </div>
            <div @click="handleClick6" @mouseover="chaImg" @mouseout="chaoutImg"
                style="overflow: hidden;width: 1.6146rem; height: 1.474rem; background-color: #7e7878; margin-left: .1354rem;position: relative;">
                <img src="../../assets/511.webp" alt=""
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                <p style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%);opacity: 0.8;
  width: 100%; height: .2083rem; position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem;
   font-weight: 400;padding-left: .0521rem;box-sizing: border-box;">隧道水幕智能预警方案</p>
            </div>
            <div @click="handleClick7" @mouseover="chaImg" @mouseout="chaoutImg"
                style="overflow: hidden;width: 1.6146rem; height: 1.474rem; background-color: #1e1a1a; margin-left: .1354rem;position: relative;">
                <img src="../../assets/512.webp" alt=""
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                <p style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%);opacity: 0.8;
  width: 100%; height: .2083rem; position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem;
   font-weight: 400;padding-left: .0521rem;box-sizing: border-box;">隧道车流量统计方案</p>
            </div>
        </div>
  
      
    
  
      <div style="width:6.5104rem;margin: 0 auto;padding-bottom: .0781rem;border-bottom: #eee solid .0052rem;">
      </div>
  
  
      <div style="display: flex;justify-content: center;margin-top: .1302rem;margin-bottom: .1302rem; ">
        <el-button @click="goBack">返回列表</el-button>
      </div>
  
  
  
  
  
    </div>
  
  
  
  
  </template>
  
  
  <script>
  
    export default {
      data(){
        return{
        
        }
      },
      
      methods: {
        goBack() {
          history.back();
        }
        ,
        handleClick() {
                this.$router.push({ path: "/samCov" });
            },
            handleClick1() {
                this.$router.push({ path: "/samCov1" });
            },
            handleClick2() {
                this.$router.push({ path: "/samCov2" });
            },
            handleClick3() {
                this.$router.push({ path: "/samCov3" });
            },
            handleClick4() {
                this.$router.push({ path: "/samCov4" });
            },
            handleClick5() {
                this.$router.push({ path: "/samCov5" });
            },
            handleClick6() {
                this.$router.push({ path: "/samCov6" });
            },
            handleClick7() {
                this.$router.push({ path: "/samCov7" });
            },
            handleClick8() {
                this.$router.push({ path: "/samCov8" });
            },
            handleClick9() {
                this.$router.push({ path: "/samCov9" });
            },
            handleClick10() {
                this.$router.push({ path: "/samCov10" });
            },
            handleClick11() {
                this.$router.push({ path: "/samCov11" });
            },
            handleClick12() {
                this.$router.push({ path: "/samCov12" });
            },
            chaImg(event) {
                const img = event.currentTarget.querySelector('img');
                img.style.transition = "0.5s"; // 设置动画过渡时间为0.5秒
                img.style.transform = "scale(1.2)"; // 设置图片放大1.2倍
                img.style.overflow = "hidden"; // 超出部分隐藏
                const pl = event.currentTarget.querySelector('p');
                pl.style.transition = "0.2s";
                pl.style.fontSize = .0938 + "rem"; 
                pl.style.background = "linear-gradient(180deg, rgba(64, 123, 245, 0.8) 0%, rgba(42, 98, 200, 0.8) 100%)";
            },
            
            
            chaoutImg(event) {
                const img = event.currentTarget.querySelector('img');
                img.style.transition = "0.5s"; // 清除过渡效果
                img.style.transform = ""; // 清除变换效果
                img.style.overflow = ""; // 清除overflow属性

                // pl.style.fontSize = .0938 + "rem"; 
                // pl.style.fontSize = .0833 + "rem"; 

                const pl = event.currentTarget.querySelector('p');
                pl.style.opacity = "1";
                pl.style.fontSize = .0833 + "rem"; 
                pl.style.transition = "0.4s";
                pl.style.background = "linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%)"
            },

  
       
        },}
   
  
      
  
  
  
  
  </script>
  
  <style scoped>

    
    
  </style>