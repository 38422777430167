<template>
    <div class="contain">
        <div style="width:100%;display: flex;justify-content: center;background-color: #F3F3F3;">
            <div style="width:7.1771rem;font-size: .0938rem;" class="opp" >
                
                <t-space style="width: 100%;margin-top: .0521rem;" direction="vertical">
                    <t-tabs theme="card" v-model="activeName" type="border-card" @change="changeTab">
                        <t-tab-panel style="height:auto;margin-bottom: .0938rem;" value="first" label="交通雷达">

                            <div style="padding: .1667rem .5rem;position: relative;">
                                <p
                                    style="font-size: .0938rem;display: flex; flex-wrap: wrap; font-weight: bold; color: #407BF5; margin: 0;">
                                    毫米波雷达 丨 IF-mmWRadar</p>
                                <div style="width:100%; display: flex; flex-wrap: wrap; margin: .0833rem 0;">
                                    <div class="div1" @mouseover="zoomInImage" @mouseout="zoomOutImage"
                                        @click="redirectToRadar1"
                                        style="width:1.375rem;height: 1.375rem;position: relative; display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/001.webp" style="height: .8229rem;">
                                        <p class="wahha">
                                        毫米波雷达</p>
                                    </div>

                                    <!-- <div class="div2 " @click="redirectToRadar2" @mouseover="zoomInImage" @mouseout="zoomOutImage"
                                        style="width:264px;position:relative; height: 264px; margin-left: 24px; display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/002.png">
                                        <p
                                            style="position: absolute; bottom:10px;font-size: 16px;font-weight: 400;color: #5B5B5B; ">
                                            全向毫米波雷达</p>
                                        <div v-show="isShown" class="divp"
                                            style="position: absolute; right:10px;bottom:8px; font-size: 14px;font-weight: 400;color: #5B5B5B; ">
                                            <p>进一步了解<img src="../assets/201.png"></p>
                                        </div>
                                        <div>if this in thr life in the round what is this roal how tu play</div>

                                        <div> the most i have pationed is the host roal in the life scall </div>

                                        <div > how to paly in the road </div>
 
                                        div></div>
                                    </div> -->

                                    <div class="div2" @click="redirectToRadar3" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/003.webp" style="height: 1.0313rem;">

                                        <p class="wahha">
                                            雷视一体机</p>
                                    </div>

                                </div>

                                <!-- <p
                                    style="font-size: 18px;display: flex; flex-wrap: wrap; font-weight: bold; color: #407BF5; margin: 0;">
                                    雷达感应模块 丨 IF-LIGHT-RADAR</p>
                                <div style="width:100%; display: flex; flex-wrap: wrap;margin:16px 0;">
                                    <div class="div1"  @click="redirectToRadar4" @mouseover="zoomInImage" @mouseout="zoomOutImage"
                                        style="width:264px;position:relative; height: 264px;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/004.png">
                                        <p
                                            style="position: absolute; bottom:10px;font-size: 14px;font-weight: 400;color: #5B5B5B; ">
                                            4GHz系列</p>
                                        <div v-show="isShown" class="divp"
                                            style="position: absolute; right:10px;bottom:8px; font-size: 14px;font-weight: 400;color: #5B5B5B; ">
                                            <p>进一步了解<img src="../assets/201.png"></p>
                                        </div>
                                    </div>

                                    <div class="div2"  @click="redirectToRadar5" @mouseover="zoomInImage" @mouseout="zoomOutImage"
                                        style="width:264px;position:relative; height: 264px; margin-left: 24px; display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/005.png">
                                        <p
                                            style="position: absolute; bottom:10px;font-size: 14px;font-weight: 400;color: #5B5B5B; ">
                                            5.8GHz系列</p>
                                        <div v-show="isShown" class="divp"
                                            style="position: absolute; right:10px;bottom:8px; font-size: 14px;font-weight: 400;color: #5B5B5B; ">
                                            <p>进一步了解<img src="../assets/201.png"></p>
                                        </div>
                                    </div>

                                    <div class="div3"  @click="redirectToRadar6" @mouseover="zoomInImage" @mouseout="zoomOutImage"
                                        style="width:264px;position:relative; height: 264px; margin-left: 24px;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/006.png">
                                        <p
                                            style="position: absolute; bottom:10px;font-size: 14px;font-weight: 400;color: #5B5B5B; ">
                                            10.525GHz系列</p>
                                        <div v-show="isShown" class="divp"
                                            style="position: absolute; right:10px;bottom:8px; font-size: 14px;font-weight: 400;color: #5B5B5B; ">
                                            <p>进一步了解<img src="../assets/201.png"></p>
                                        </div>
                                    </div>

                                    <div class="div4"  @click="redirectToRadar7" @mouseover="zoomInImage" @mouseout="zoomOutImage"
                                        style="width:264px;position:relative; height: 264px; margin-left: 24px;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/007.png">
                                        <p
                                            style="position: absolute; bottom:10px;font-size: 14px;font-weight: 400;color: #5B5B5B; ">
                                            24GHz系列</p>
                                        <div v-show="isShown" class="divp"
                                            style="position: absolute; right:10px;bottom:8px; font-size: 14px;font-weight: 400;color: #5B5B5B; ">
                                            <p>进一步了解<img src="../assets/201.png"></p>

                                            <P>what is the biggest one  how to you play in the</P>
                                        </div>
                                    </div>
                                </div> -->


                                <p
                                    style="font-size: .0938rem;display: flex; flex-wrap: wrap; font-weight: bold; color: #407BF5; margin: 0;">
                                  边缘计算服务器
                                </p>


                                <div style="width:100%; display: flex; flex-wrap: wrap;margin:.0833rem 0;">
                                    <!-- <div class="div1" @click="redirectToRadar8" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/008.webp">
                                        <p class="wahha">
                                            激光雷达</p>
                                    </div> -->

                                    <div class="div2" @click="redirectToRadar9" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem; display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/009.webp"  style="height: 1.1563rem;">
                                        <p class="wahha">
                                            边缘计算服务器</p>
                                    </div>
                                </div>

                                <!-- <t-pagination style="position: absolute; left: -40%;" :total="30" :totalContent="false" showPreviousAndNextBtn :showPageSize="false" showPageNumber/> -->

                            </div>

                        </t-tab-panel>


                        <t-tab-panel value="six" style="height:auto;margin-bottom: .0938rem;" label="雷达通信模组">
                            <div style="padding: .1667rem .5rem;position: relative;">

                                <p
                                    style="font-size: .0938rem;display: flex; flex-wrap: wrap; font-weight: bold; color: #407BF5; margin: 0;">
                                    <!-- 雷达感应模块 丨 IF-LIGHT-RADAR -->
                                    雷达感应模组
                                </p>
                                <div style="width:100%; display: flex; flex-wrap: wrap;margin:.0833rem 0;">
                                    <div class="div1" @click="redirectToRadar4" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/m111.webp" style="height: .9792rem;">
                                        <p class="wahha">
                                            4GHz系列</p>
                                    </div>

                                    <div class="div2" @click="redirectToRadar5" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem; display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/m222.webp" style="height:.9792rem;">
                                       

                                        <p class="wahha">
                                            5.8GHz系列</p>
                                    </div>

                                    <div class="div3" @click="redirectToRadar6" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/m105.webp" style="height: 1.1979rem;">
                                      
                                        <p class="wahha">
                                            10.525GHz系列</p>
                                        
                                    </div>

                                    <div class="div4" @click="redirectToRadar7" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/m240.webp" style="height: 1.1979rem;">
                                     

                                        <p class="wahha">
                                            24GHz系列</p>
                                        
                                    </div>
                                </div>
                            </div>

                            <!-- <t-pagination style="position: absolute;left: -40%;" :total="30" :totalContent="false"
                                    showPreviousAndNextBtn :showPageSize="false" showPageNumber /> -->


                        </t-tab-panel>

                        <!-- 

                         -->

                        <t-tab-panel value="seven" style="height:auto;margin-bottom: .0938rem;" label="智能收费亭">

                            <div style="padding: .1667rem .5rem;position: relative;">

                                <p
                                    style="font-size: .0938rem;display: flex; flex-wrap: wrap; font-weight: bold; color: #407BF5; margin: 0;">
                                    智能收费亭</p>
                                <div style="width:100%; display: flex; flex-wrap: wrap;margin:.0833rem 0;">

                                    <div style="width:100%; display: flex; flex-wrap: wrap;margin:.0833rem 0;">
                                        <div class="div1" @click="redirectToTraffic1" traffic @mouseover="zoomInImage"
                                            @mouseout="zoomOutImage"
                                            style="width:1.375rem;position:relative; height: 1.375rem;display: flex;justify-content: center;align-items: center;">
                                            <img src="../assets/011.webp" style="height:1.3125rem">
                                            <p class="wahha">
                                                智能单向收费亭</p>
                                        </div>

                                  

                                        <div class="div2" @click="redirectToTraffic3" @mouseover="zoomInImage"
                                            @mouseout="zoomOutImage"
                                            style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem;display: flex;justify-content: center;align-items: center;">
                                            <img src="../assets/013.webp" style="height: 1.375rem;">
                                            <!-- <p
                                                style="position: absolute; bottom:.026rem; font-size: .0938rem;font-weight: 400;color: #5B5B5B; ">
                                                </p>
                                            <div v-show="isShown" class="divp"
                                                style="position: absolute; right:.0521rem;bottom:.026rem; font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                                <p>进一步了解<img src="../assets/201.png"></p>
                                            </div> -->
                                            <p class="wahha">
                                                智能双向收费亭</p>
                                            
                                        </div>

                      
                                    </div>
                                </div>
                            </div>


                            <!-- <t-pagination style="position: absolute;left: -40%;" :total="30" :totalContent="false"
                                    showPreviousAndNextBtn :showPageSize="false" showPageNumber /> -->


                        </t-tab-panel>












                        <!-- 

                          -->


                        <!--  -->



                        <t-tab-panel value="eight" style="height:auto;margin-bottom: .0938rem;" label="交通诱导系列">
                            <div style="padding: .1667rem .5rem;position: relative;">

                                <p
                                    style="font-size: .0938rem;display: flex; flex-wrap: wrap; font-weight: 400; color: #407BF5; margin: 0;">
                                    智能雾灯
                                </p>

                                <div style="width:100%; display: flex; flex-wrap: wrap;margin:.0833rem 0;">
                                    <div class="div1" @click="redirectToTraffic5" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/015.webp" style="height: .8594rem;">
                                        <!-- <p
                                            style="position: absolute; bottom:.026rem; left:.0521rem;font-size: .0938rem;font-weight: 400;color: #5B5B5B; ">
                                            智能雾灯</p>


                                        <div v-show="isShown" class="divp"
                                            style="position: absolute; right:.0521rem;bottom:.026rem; font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                            <p>进一步了解<img src="../assets/201.png"></p>
                                        </div> -->

                                        <p class="wahha">
                                            智能雾灯</p>

                                    </div>

                                    <div class="div2" @click="redirectToTraffic6" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem; display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/016.webp" style="height: 1.375rem;">
                                        <!-- <p
                                            style="position: absolute; bottom:.026rem;font-size: .0938rem;font-weight: 400;color: #5B5B5B; ">
                                            带字智能雾灯</p>
                                        <div v-show="isShown" class="divp"
                                            style="position: absolute; right:.0521rem;bottom:.026rem; font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                            <p>进一步了解<img src="../assets/201.png"></p>
                                        </div> -->

                                        <p class="wahha">
                                            带字智能雾灯</p>
                                    </div>

                                    <div class="div3" @click="redirectToTraffic7" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/017.webp" style="height: 1.375rem;">
                                        <!-- <p
                                            style="position: absolute; bottom:.026rem;font-size: .0938rem;font-weight: 400;color: #5B5B5B; ">
                                            小型智能雾灯</p>
                                        <div v-show="isShown" class="divp"
                                            style="position: absolute; right:.0521rem;bottom:.026rem; font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                            <p>进一步了解<img src="../assets/201.png"></p>
                                        </div> -->

                                        <p class="wahha">
                                            小型智能雾灯</p>
                                    </div>
                                </div>

                                <p
                                    style="font-size: .0938rem;display: flex; flex-wrap: wrap; font-weight: 400; color: #407BF5; margin: 0;">
                                    交通劝导装置</p>
                                <div style="width:100%; display: flex; flex-wrap: wrap;margin:.0833rem 0;">
                                    <div class="div1" @click="redirectToTraffic8" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/018.webp"  style="height: 1.1198rem;">
                                        <!-- <p
                                            style="position: absolute; bottom:.026rem;font-size: .0938rem;font-weight: 400;color: #5B5B5B; ">
                                            交通劝导装置</p>
                                        <div v-show="isShown" class="divp"
                                            style="position: absolute; right:.0521rem;bottom:.026rem; font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                            <p>进一步了解<img src="../assets/201.png"></p>
                                        </div> -->
                                        
                                        <p class="wahha">
                                            交通劝导装置</p>
                                    </div>

                                    <div class="div2" @click="redirectToTraffic9" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem; display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/019.webp"  style="height: 1.1198rem;">
                                        <!-- <p
                                            style="position: absolute; bottom:.026rem;font-size: .0938rem;font-weight: 400;color: #5B5B5B; ">
                                            太阳能交通劝导装置</p>
                                        <div v-show="isShown" class="divp"
                                            style="position: absolute; right:.0521rem;bottom:.026rem; font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                            <p>进一步了解<img src="../assets/201.png"></p>
                                        </div> -->

                                        <p class="wahha">
                                            太阳能交通劝导装置</p>
                                    </div>


                                </div>



                            </div>

                        </t-tab-panel>












                        <!-- <t-tab-panel value="second" style="height:auto;margin-bottom: .0938rem;" label="智慧交通系列">
                            <div style="padding: .1667rem .5rem;position: relative;">

                                <p
                                    style="font-size: .0938rem;display: flex; flex-wrap: wrap; font-weight: bold; color: #407BF5; margin: 0;">
                                    智能收费亭</p>
                                <div style="width:100%; display: flex; flex-wrap: wrap;margin:.0833rem 0;">

                                    <div style="width:100%; display: flex; flex-wrap: wrap;margin:.0833rem 0;">
                                        <div class="div1" @click="redirectToTraffic1" traffic @mouseover="zoomInImage" @mouseout="zoomOutImage"
                                            style="width:1.375rem;position:relative; height: 1.375rem;display: flex;justify-content: center;align-items: center;">
                                            <img src="../assets/011.png">
                                            <p
                                                style="position: absolute; bottom:.026rem;font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                                智能收费亭</p>
                                            <div v-show="isShown" class="divp"
                                                style="position: absolute; right:.0521rem;bottom:.026rem; font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                                <p>进一步了解<img src="../assets/201.png"></p>
                                            </div>
                                        </div>

                                        <div class="div2" @click="redirectToTraffic2" @mouseover="zoomInImage" @mouseout="zoomOutImage"
                                            style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem; display: flex;justify-content: center;align-items: center;">
                                            <img src="../assets/012.png">
                                            <p
                                                style="position: absolute; bottom:.026rem; font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                                智能单门双向收费亭</p>
                                            <div v-show="isShown" class="divp"
                                                style="position: absolute; right:.0521rem;bottom:.026rem; font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                                <p>进一步了解<img src="../assets/201.png"></p>
                                            </div>
                                        </div>

                                        <div class="div3"  @click="redirectToTraffic3" @mouseover="zoomInImage" @mouseout="zoomOutImage"
                                            style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem;display: flex;justify-content: center;align-items: center;">
                                            <img src="../assets/013.png">
                                            <p
                                                style="position: absolute; bottom:.026rem; font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                                智能双门双向收费亭</p>
                                            <div v-show="isShown" class="divp"
                                                style="position: absolute; right:.0521rem;bottom:.026rem; font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                                <p>进一步了解<img src="../assets/201.png"></p>
                                            </div>
                                        </div>

                                        <div class="div4"  @click="redirectToTraffic4" @mouseover="zoomInImage" @mouseout="zoomOutImage"
                                            style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem;display: flex;justify-content: center;align-items: center;">
                                            <img src="../assets/014.png">
                                            <p
                                                style="position: absolute; bottom:.026rem;font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                                智慧定制收费亭</p>
                                            <div v-show="isShown" class="divp"
                                                style="position: absolute; right:.0521rem;bottom:.026rem; font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                                <p>进一步了解<img src="../assets/201.png"></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <p
                                    style="font-size: .0938rem;display: flex; flex-wrap: wrap; font-weight: 400; color: #407BF5; margin: 0;">
                                    智能雾灯
                                </p>

                                <div style="width:100%; display: flex; flex-wrap: wrap;margin:.0833rem 0;">
                                    <div class="div1"  @click="redirectToTraffic5" @mouseover="zoomInImage" @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/015.png">
                                        <p
                                            style="position: absolute; bottom:.026rem; left:.0521rem;font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                            智能雾灯</p>


                                        <div v-show="isShown" class="divp"
                                            style="position: absolute; right:.0521rem;bottom:.026rem; font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                            <p>进一步了解<img src="../assets/201.png"></p>
                                        </div>

                                    </div>

                                    <div class="div2"  @click="redirectToTraffic6" @mouseover="zoomInImage" @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem; display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/016.png">
                                        <p
                                            style="position: absolute; bottom:.026rem;font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                            带字智能雾灯</p>
                                        <div v-show="isShown" class="divp"
                                            style="position: absolute; right:.0521rem;bottom:.026rem; font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                            <p>进一步了解<img src="../assets/201.png"></p>
                                        </div>
                                    </div>

                                    <div class="div3"  @click="redirectToTraffic7" @mouseover="zoomInImage" @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/017.png">
                                        <p
                                            style="position: absolute; bottom:.026rem;font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                            小型智能雾灯</p>
                                        <div v-show="isShown" class="divp"
                                            style="position: absolute; right:.0521rem;bottom:.026rem; font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                            <p>进一步了解<img src="../assets/201.png"></p>
                                        </div>
                                    </div>

                                    
                                </div>



                                <p
                                    style="font-size: .0938rem;display: flex; flex-wrap: wrap; font-weight: 400; color: #407BF5; margin: 0;">
                                    交通劝导装置</p>
                                <div style="width:100%; display: flex; flex-wrap: wrap;margin:.0833rem 0;">
                                    <div class="div1"  @click="redirectToTraffic8" @mouseover="zoomInImage" @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/018.png">
                                        <p
                                            style="position: absolute; bottom:.026rem;font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                            交通劝导装置</p>
                                        <div v-show="isShown" class="divp"
                                            style="position: absolute; right:.0521rem;bottom:.026rem; font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                            <p>进一步了解<img src="../assets/201.png"></p>
                                        </div>
                                    </div>

                                    <div class="div2" @click="redirectToTraffic9" @mouseover="zoomInImage" @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem; display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/019.png">
                                        <p
                                            style="position: absolute; bottom:.026rem;font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                            太阳能交通劝导装置</p>
                                        <div v-show="isShown" class="divp"
                                            style="position: absolute; right:.0521rem;bottom:.026rem; font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                            <p>进一步了解<img src="../assets/201.png"></p>
                                        </div>
                                    </div>


                                </div>

                            

                            </div>
                        </t-tab-panel> -->


                        <t-tab-panel value="third" style="height:auto;margin-bottom: .026rem;" label="可变情报板系列">

                            <div style="padding: .1667rem .5rem;position: relative;">
                                <div style="width:100%; display: flex; flex-wrap: wrap;margin:.0833rem 0;">
                                    <div class="div1" @click="redirectToHome1" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/020.webp"  style="height: 1.375rem;">
                                        <!-- <p
                                            style="position: absolute; bottom:.026rem;font-size: .0938rem;font-weight: 400;color: #5B5B5B; ">
                                            门架式可变情报板</p>
                                        <div v-show="isShown" class="divp"
                                            style="position: absolute; right:.0521rem;bottom:.026rem; font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                            <p>进一步了解<img src="../assets/201.png"></p>
                                        </div> -->

                                        <p class="wahha">
                                            门架式可变情报板</p>
                                    </div>


                                    <div class="div2" @click="redirectToHome2" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem; display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/021.webp"  style="height: 1.375rem;">
                                        <!-- <p
                                            style="position: absolute; bottom:.026rem;font-size: .0938rem;font-weight: 400;color: #5B5B5B; ">
                                            F型(悬臂式)可变情报板</p>
                                        <div v-show="isShown" class="divp"
                                            style="position: absolute; right:.0521rem;bottom:.026rem; font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                            <p>进一步了解<img src="../assets/201.png"></p>
                                        </div> -->
                                        <p class="wahha">
                                            F型(悬臂式)可变情报板</p>

                                    </div>

                                    <div class="div3" @click="redirectToHome3" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/022.webp"  style="height: 1.1198rem;">
                                        <!-- <p
                                            style="position: absolute; bottom:.026rem;font-size: .0938rem;font-weight: 400;color: #5B5B5B; ">
                                            立柱型可变情报板</p>
                                        <div v-show="isShown" class="divp"
                                            style="position: absolute; right:.0521rem;bottom:.026rem; font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                            <p>进一步了解<img src="../assets/201.png"></p>
                                        </div> -->

                                        <p class="wahha">
                                            立柱型可变情报板</p>
                                    </div>

                                    <div class="div4" @click="redirectToHome4" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/023.webp"  style="height: 1.375rem;">
                                        <!-- <p
                                            style="position: absolute; bottom:.026rem;font-size: .0938rem;font-weight: 400;color: #5B5B5B; ">
                                            可变限速标志</p>
                                        <div v-show="isShown" class="divp"
                                            style="position: absolute; right:.0521rem;bottom:.026rem; font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                            <p>进一步了解<img src="../assets/201.png"></p>
                                        </div> -->

                                        <p class="wahha">
                                            可变限速标志</p>
                                    </div>
                                </div>



                                <div style="width:100%; display: flex; flex-wrap: wrap;margin:.0833rem 0;">
                                    <!-- <div class="div1" @click="redirectToHome9" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="overflow: hidden; width:1.375rem;position:relative; height: 1.375rem;display: flex;justify-content: center;align-items: center;">
                                        <img class="whhimg" style="position: absolute;top: 0;" src="../assets/028.webp" >
                                   

                                        <p class="wahha">
                                            可变情报板发布与控制软件</p>
                                    </div> -->

                                    <div class="div1" @click="redirectToHome10" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem; display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/029.webp"  style="height: 1.1198rem;">
                                        <!-- <p
                                            style="position: absolute; bottom:.026rem;font-size: .0938rem;font-weight: 400;color: #5B5B5B; ">
                                            轻量化LED屏</p>
                                        <div v-show="isShown" class="divp"
                                            style="position: absolute; right:.0521rem;bottom:.026rem; font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                            <p>进一步了解<img src="../assets/201.png"></p>
                                        </div> -->

                                        <p class="wahha">
                                            轻量化LED屏</p>
                                    </div>

                                    
                                    <div class="div2" @click="redirectToHome16" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/035.webp"  style="height: 1.1198rem;">
                                        <!-- <p
                                            style="position: absolute; bottom:.026rem;font-size: .0938rem;font-weight: 400;color: #5B5B5B; ">
                                            折叠屏</p>
                                        <div v-show="isShown" class="divp"
                                            style="position: absolute; right:.0521rem;bottom:.026rem; font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                            <p>进一步了解<img src="../assets/201.png"></p>
                                        </div> -->
                                        <p class="wahha">
                                            折叠屏</p> 
                                    </div>


                                    <div class="div3" @click="redirectToHome5" @mouseover="zoomInImage"
                                    @mouseout="zoomOutImage"
                                    style="width:1.375rem;position:relative;margin-left: .125rem; height: 1.375rem;display: flex;justify-content: center;align-items: center;">
                                    <img src="../assets/隧道内可变情报板.png"  style="height: 1.1198rem;">
                                    <!-- <p
                                        style="position: absolute; bottom:.026rem; font-size: .0938rem;font-weight: 400;color: #5B5B5B; ">
                                        隧道内可变情报板</p>

                                    <div v-show="isShown" class="divp"
                                        style="position: absolute; right:.0521rem;bottom:.026rem; font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                        <p>进一步了解<img src="../assets/201.png"></p>
                                    </div> -->
                                    <p class="wahha">
                                        隧道内可变情报板</p> 

                                </div>


                                <div class="div4" @mouseover="zoomInImage" @mouseout="zoomOutImage"
                                @click="redirectToHome6"
                                style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem; display: flex;justify-content: center;align-items: center;">
                                <img src="../assets/025.webp"  style="height: 1.1198rem;">
                                <!-- <p
                                    style="position: absolute; bottom:.026rem;font-size: .0938rem;font-weight: 400;color: #5B5B5B; ">
                                    城市交通诱导屏</p>
                                <div v-show="isShown" class="divp"
                                    style="position: absolute; right:.0521rem;bottom:.026rem; font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                    <p>进一步了解<img src="../assets/201.png"></p>
                                </div> -->
                                <p class="wahha">
                                    城市交通诱导屏</p> 
                            </div>

                                    <!-- <div class="div3" @click="redirectToHome11" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/030.webp">
                                      

                                        <p class="wahha">
                                            P16情报板</p>
                                    </div> -->
<!-- 
                                    <div class="div4" @click="redirectToHome12" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/031.webp">
                                   
                                        <p class="wahha">
                                            P33情报板4R2G</p>
                                    </div> -->
                                </div>

    
                                <div style="width:100%; display: flex; flex-wrap: wrap;margin:.0833rem 0;">
                         

                              

                                    <div class="div1" @click="redirectToHome7" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem;  display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/026.webp"  style="height: 1.1198rem;">

                                         <p class="wahha">
                                            室内信息显示屏</p> 
                                    </div>

                                </div>


                                <!-- <el-pagination style="position: absolute;bottom: .0781rem;  right:48%;margin-top: .0781rem;"
                                    background layout="prev, pager, next" :total="20">
                                </el-pagination> -->
                            </div>

                        </t-tab-panel>


                        <!--  -->


                        <t-tab-panel value="nine" style="height:auto;margin-bottom: .0938rem;" label="收费车道设备系列">
                            <div style="padding: .1667rem .5rem;position: relative;">

                                <div style="width:100%; display: flex; flex-wrap: wrap;margin:.0833rem 0;">
                                    <div class="div1" @click="redirectToDis1" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/费额显示器.png"  style="height: 1.1198rem;">
                                        <!-- <p
                                            style="position: absolute; bottom:.026rem;font-size: .0938rem;font-weight: 400;color: #5B5B5B; ">
                                            费额显示器</p>
                                        <div v-show="isShown" class="divp"
                                            style="position: absolute; right:.0521rem;bottom:.026rem; font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                            <p>进一步了解<img src="../assets/201.png"></p>
                                        </div> -->
                                        <p class="wahha">
                                            费额显示器</p> 
                                    </div>

                                    <div class="div2" @click="redirectToDis4" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/ETCF.png"  style="height: 1.1198rem;">
                                        <!-- <p
                                            style="position: absolute; bottom:0rem;font-size: .0938rem;font-weight: 400;color: #5B5B5B; ">
                                            ETC/混合车道雨棚信号灯</p>
                                        <div v-show="isShown" class="divp"
                                            style="position: absolute; right:.0521rem;bottom:.026rem; font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                            <p>进一步了解<img src="../assets/201.png"></p>
                                        </div> -->

                                        <p class="wahha">
                                            ETC/混合车道雨棚信号灯</p> 
                                    </div>

                                    <div class="div3" @click="redirectToDis2" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem; display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/多功能费显111.png"  style="height: 1.1198rem;">
                                        <!-- <p
                                            style="position: absolute; bottom:.026rem;font-size: .0938rem;font-weight: 400;color: #5B5B5B; ">
                                            多功能费显</p>
                                        <div v-show="isShown" class="divp"
                                            style="position: absolute; right:.0521rem;bottom:.026rem; font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                            <p>进一步了解<img src="../assets/201.png"></p>
                                        </div> -->

                                        <p class="wahha">
                                            多功能费显</p> 
                                    </div>

                                    <div class="div4" @click="redirectToDis3" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/隧道车道指示标志1.png"  style="height: 1.1198rem;">
                                        <!-- <p
                                            style="position: absolute; bottom:.026rem;font-size: .0938rem;font-weight: 400;color: #5B5B5B; ">
                                            隧道车道指示标志</p>
                                        <div v-show="isShown" class="divp"
                                            style="position: absolute; right:.0521rem;bottom:.026rem; font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                            <p>进一步了解<img src="../assets/201.png"></p>
                                        </div> -->
                                        <p class="wahha">
                                            隧道车道指示标志</p> 
                                    </div>


                                </div>

                                <div style="width:100%; display: flex; flex-wrap: wrap;margin:.0833rem 0;">
                                    <div class="div1" @click="redirectToDis5" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/雨棚信号灯111.png"  style="height: 1.1198rem;">

                                        <p class="wahha">
                                            雨棚信号灯</p> 
                                    </div>

                                    <div class="div2" @click="redirectToDis8" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/雾灯12345.png"  style="height: 1.1198rem;">
                                        <p class="wahha">
                                            雾灯</p> 
                                    </div>


                                </div>



                            </div>

                        </t-tab-panel>

                        <!--  -->



                        <t-tab-panel value="four" style="height:auto;margin-bottom: .0938rem;" label="LED显示标志系列">

                            <div style="padding: .1667rem .5rem;position: relative;">
                                <!-- <div style="width:100%; display: flex; flex-wrap: wrap;margin:.0833rem 0;"> -->
                                <!-- <div class="div1" @click="redirectToDis1" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/0401.png">
                                        <p
                                            style="position: absolute; bottom:.026rem;font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                            费额显示器</p>
                                        <div v-show="isShown" class="divp"
                                            style="position: absolute; right:.0521rem;bottom:.026rem; font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                            <p>进一步了解<img src="../assets/201.png"></p>
                                        </div>
                                    </div> -->

                                <!-- <div class="div2" @click="redirectToDis2" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem; display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/041.png">
                                        <p
                                            style="position: absolute; bottom:.026rem;font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                            多功能费显</p>
                                        <div v-show="isShown" class="divp"
                                            style="position: absolute; right:.0521rem;bottom:.026rem; font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                            <p>进一步了解<img src="../assets/201.png"></p>
                                        </div>
                                    </div> -->

                                <!-- <div class="div3" @click="redirectToDis3" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/042.png">
                                        <p
                                            style="position: absolute; bottom:.026rem;font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                            隧道车道指示标志</p>
                                        <div v-show="isShown" class="divp"
                                            style="position: absolute; right:.0521rem;bottom:.026rem; font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                            <p>进一步了解<img src="../assets/201.png"></p>
                                        </div>
                                    </div> -->

                                <!-- <div class="div4" @click="redirectToDis4" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/043.png">
                                        <p
                                            style="position: absolute; bottom:0rem;font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                            ETC/混合车道雨棚信号灯</p>
                                        <div v-show="isShown" class="divp"
                                            style="position: absolute; right:.0521rem;bottom:.026rem; font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                            <p>进一步了解<img src="../assets/201.png"></p>
                                        </div>
                                    </div> -->
                                <!-- </div> -->


                                <div style="width:100%; display: flex; flex-wrap: wrap;margin:.0833rem 0;">
                                    <div class="div1" @click="redirectToDis9" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/048.webp"  style="height: 1.1198rem;">
                                        <p class="wahha">
                                            LED诱导系统</p> 
                                    </div>

                                    <div class="div2" @click="redirectToDis10" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem; display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/049.webp"  style="height: 1.1198rem;">
                                        <p class="wahha">
                                            电光轮廓标</p> 
                                    </div>

                                    <div class="div3" @click="redirectToDis11" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/050.webp"  style="height: 1.1198rem;">
                                        <p class="wahha">
                                            LED诱导标志控制器</p> 
                                    </div>

                                    <div class="div4" @click="redirectToDis12" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/051.webp"  style="height: 1.1198rem;">
                                      
                                        <p class="wahha">
                                            节能型隧道诱导指示标志</p> 
                                    </div>
                                </div>


                                <div style="width:100%; display: flex; flex-wrap: wrap;margin:.0833rem 0;">

                                    <div class="div1" @click="redirectToDis6" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem;  display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/045.webp"  style="height: 1.1198rem;">
                                        <p class="wahha">
                                            交通信号灯</p> 
                                    </div>

                                    <div class="div2" @click="redirectToDis7" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/046.webp"  style="height: 1.1198rem;">
                                        <p class="wahha">
                                            车道通行灯</p> 
                                    </div>




                                </div>



                                <!-- <div style="width:100%; display: flex; flex-wrap: wrap;margin:.0833rem 0;"> -->
                                <!-- <div class="div1" @click="redirectToDis5" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/044.png">

                                        <p
                                            style="position: absolute; bottom:.026rem; font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                            雨棚信号灯</p>
                                        <div v-show="isShown" class="divp"
                                            style="position: absolute; right:.0521rem;bottom:.026rem; font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                            <p>进一步了解<img src="../assets/201.png"></p>
                                        </div>
                                    </div> -->


                                <!-- <div class="div4" @click="redirectToDis8" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/047.png">
                                        <p
                                            style="position: absolute; bottom:.026rem;font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                            雾灯</p>
                                        <div v-show="isShown" class="divp"
                                            style="position: absolute; right:.0521rem;bottom:.026rem; font-size: .0729rem;font-weight: 400;color: #5B5B5B; ">
                                            <p>进一步了解<img src="../assets/201.png"></p>
                                        </div>
                                    </div> -->
                                <!-- </div> -->


                                <!-- <t-pagination style="position: absolute;left: -40%;" :total="30" :totalContent="false" showPreviousAndNextBtn :showPageSize="false" showPageNumber/> -->
                            </div>

                        </t-tab-panel>

                        <t-tab-panel value="Five" style="height:auto" label="LED照明系列">
                            <div style="padding: .1667rem .5rem;position: relative;">
                                <div style="width:100%; display: flex; flex-wrap: wrap;margin:.0833rem 0;">
                                    <div class="div1" @click="redirectToLight1" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/5800.png"  style="height: 1.1198rem;">
                                      

                                        <p class="wahha">
                                            LED路灯-0708系列</p> 
                                    </div>

                                    <div class="div2" @click="redirectToLight2" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem; display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/5801.png"  style="height: 1.1198rem;">
                                 

                                        <p class="wahha">
                                            LED路灯-0720系列</p> 
                                    </div>

                                    <div class="div3" @click="redirectToLight3" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/5802.png"  style="height: 1.1198rem;">
                                        <p class="wahha">
                                            LED隧道灯</p> 
                                    </div>

                                    <div class="div4" @click="redirectToLight4" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/5803.png"  style="height: 1.1198rem;">
                                        <p class="wahha">
                                            LED高杆灯</p> 
                                    </div>
                                </div>

                                <div style="width:100%; display: flex; flex-wrap: wrap;margin:.0833rem 0;">
                                    <div class="div1" @click="redirectToLight5" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/5804.png"  style="height: 1.1198rem;">
                                        <p class="wahha">
                                           低位照明</p> 
                                    </div>


                                </div>
                                <!-- <t-pagination style="position: absolute;bottom: 15px; left: -40%;" :total="30" :totalContent="false" showPreviousAndNextBtn :showPageSize="false" showPageNumber/> -->

                            </div>

                        </t-tab-panel>

                    </t-tabs>
                </t-space>

            </div>
        </div>
    </div>


</template>

<script>
    export default {

        data() {
            return {
                isShown: false,

                productCenterImage: require('../assets/pood1.webp'),
                activeName: 'first',

            };
        },
        //         mounted() {
        //   this.handleTabChange(this.$route.query.tab);
        // },
        // watch: {
        //   '$route.query.tab': {
        //     immediate: true,
        //     handler(newTab) {
        //       this.handleTabChange(newTab);
        //     }
        //   }
        // },
        // mounted() {
        //     const tab = this.$route.query.tab;
        //     if (tab === 'first') {
        //         this.value2 = 'first'; // 设置第一个选项卡为激活状态
        //     } else if (tab === 'second') {
        //         this.value2 = 'second'; // 设置第二个选项卡为激活状态
        //     } else if (tab === 'third') {
        //         this.value2 = 'third'; // 设置第三个选项卡为激活状态
        //     } else if (tab === 'four') {
        //         this.value2 = 'four';
        //     } else if (tab === 'Five') {
        //         this.value2 = 'Five';
        //     } else if (tab === 'six') {
        //         this.value2 = 'six';
        //     }
        //     else if (tab === 'seven') {
        //         this.value2 = 'seven';
        //     }else if (tab === 'eight') {
        //         this.value2 = 'eight';
        //     }else if (tab === 'nine') {
        //         this.value2 = 'nine';
        //     }




        // },
        mounted() {
            this.handleTabChange(this.$route.query.tab);
        },
        watch: {
            '$route.query.tab': {
                immediate: true,
                handler(newTab) {
                    this.handleTabChange(newTab);
                }
            }
        },


        methods: {
            handleTabChange(tab) {
                if (tab === 'first') {
                    this.activeName = 'first'; // 设置第一个选项卡为激活状态
                } else if (tab === 'second') {
                    this.activeName = 'second'; // 设置第二个选项卡为激活状态
                } else if (tab === 'third') {
                    this.activeName = 'third'; // 设置第三个选项卡为激活状态
                }
                else if (tab === 'four') {
                    this.activeName = 'four'; // 设置第三个选项卡为激活状态
                }

                else if (tab === 'Five') {
                    this.activeName = 'Five'; // 设置第三个选项卡为激活状态
                }
                else if (tab === 'six') {
                    this.activeName = 'six'; // 设置第三个选项卡为激活状态
                } else if (tab === 'seven') {
                    this.activeName = 'seven'; // 设置第三个选项卡为激活状态
                }
                else if (tab === 'eight') {
                    this.activeName = 'eight'; // 设置第三个选项卡为激活状态
                }
                else if (tab === 'nine') {
                    this.activeName = 'nine'; // 设置第三个选项卡为激活状态
                }

                console.log(tab); // 打印传递过来的 tab 参数
            },

            zoomInImage(event) {
                const img = event.currentTarget.querySelector('img');
                img.style.transition = "0.3s";
                img.style.transform = 'scale(1.2)';
                // const divp = event.currentTarget.querySelector('.divp');
                // divp.style.display = 'block';
                const pl = event.currentTarget.querySelector('p');
                pl.style.transition = "0.2s";
                pl.style.fontSize = .0938 + "rem";
                pl.style.background = "linear-gradient(180deg, rgba(64, 123, 245, 0.8) 0%, rgba(42, 98, 200, 0.8) 100%)";


                // const pl = event.currentTarget.querySelector('p');
                // pl.style.transition = "0.2s";
                // pl.style.fontSize = 18 + "px"
                // pl.style.background = "linear-gradient(180deg, rgba(64, 123, 245, 0.8) 0%, rgba(42, 98, 200, 0.8) 100%)";

            },
            //另外当前元素的divp
            zoomOutImage(event) {

                const img = event.currentTarget.querySelector('img');
                img.style.transform = 'scale(1)';
                // const divp = event.currentTarget.querySelector('.divp');
                // divp.style.display = 'none';
                const pl = event.currentTarget.querySelector('p');
                pl.style.opacity = "1";
                pl.style.fontSize = .0833 + "rem";
                pl.style.transition = "0.4s";
                pl.style.background = "linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%)"

            },
            redirectToRadar1() {
                this.$router.push('/millimeterWaveRadar1');
                // this.$router.push('/inTrans1');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToRadar2() {
                this.$router.push('/millimeterWaveRadar2');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToRadar3() {
                this.$router.push('/millimeterWaveRadar3');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToRadar4() {
                this.$router.push('/millimeterWaveRadar4');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToRadar5() {
                this.$router.push('/millimeterWaveRadar5');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToRadar6() {
                this.$router.push('/millimeterWaveRadar6');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToRadar7() {
                this.$router.push('/millimeterWaveRadar7');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToRadar8() {
                this.$router.push('/millimeterWaveRadar8');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToRadar9() {
                this.$router.push('/millimeterWaveRadar9');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToHome1() {
                this.$router.push('/mationBan1');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

            },
            redirectToHome2() {
                this.$router.push('/mationBan2');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

            },
            redirectToHome3() {
                this.$router.push('/mationBan3');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

            },
            redirectToHome4() {
                this.$router.push('/mationBan4');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

            },
            redirectToHome5() {
                this.$router.push('/mationBan5');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

            },
            redirectToHome6() {
                this.$router.push('/mationBan6');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

            },
            redirectToHome7() {
                this.$router.push('/mationBan7');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

            },
            redirectToHome8() {
                this.$router.push('/mationBan8');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

            },
            redirectToHome9() {
                this.$router.push('/mationBan9');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

            },
            redirectToHome10() {
                this.$router.push('/mationBan10');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

            },
            redirectToHome11() {
                this.$router.push('/mationBan11');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

            },
            redirectToHome12() {
                this.$router.push('/mationBan12');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

            },
            redirectToHome13() {
                this.$router.push('/mationBan13');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

            },
            redirectToHome14() {
                this.$router.push('/mationBan14');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

            },
            redirectToHome15() {
                this.$router.push('/mationBan15');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

            },
            redirectToHome16() {
                this.$router.push('/mationBan16');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

            },
            redirectToDis1() {
                this.$router.push('/ledDisplay1');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToDis2() {
                this.$router.push('/ledDisplay2');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToDis3() {
                this.$router.push('/ledDisplay3');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToDis4() {
                this.$router.push('/ledDisplay4');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToDis5() {
                this.$router.push('/ledDisplay5');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToDis6() {
                this.$router.push('/ledDisplay6');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToDis7() {
                this.$router.push('/ledDisplay7');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToDis8() {
                this.$router.push('/ledDisplay8');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToDis9() {
                this.$router.push('/ledDisplay9');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToDis10() {
                this.$router.push('/ledDisplay10');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToDis11() {
                this.$router.push('/ledDisplay11');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToDis12() {
                this.$router.push('/ledDisplay12');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToLight1() {
                this.$router.push('/ledLighting1');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToLight2() {
                this.$router.push('/ledLighting2');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToLight3() {
                this.$router.push('/ledLighting3');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToLight4() {
                this.$router.push('/ledLighting4');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToLight5() {
                this.$router.push('/ledLighting5');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToTraffic1() {
                this.$router.push('/inTrans1');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToTraffic2() {
                this.$router.push('/inTrans2');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToTraffic3() {
                this.$router.push('/inTrans3');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置//这里要改哦
            },
            redirectToTraffic4() {
                this.$router.push('/inTrans4');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToTraffic5() {
                this.$router.push('/inTrans5');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToTraffic6() {
                this.$router.push('/inTrans6');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToTraffic7() {
                this.$router.push('/inTrans7');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToTraffic8() {
                this.$router.push('/inTrans8');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToTraffic9() {
                this.$router.push('/inTrans9');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            changeTab(newValue) {
                this.value2 = newValue;
                this.$emit('tabChanged', newValue);
                if (newValue === 'first') {
                    this.productCenterImage = require('../assets/pood1.webp');
                } else if (newValue === 'second') {
                    this.productCenterImage = require('../assets/pod1.png');
                } else if (newValue === 'third') {
                    this.productCenterImage = require('../assets/pood2.png');
                } else if (newValue === 'four') {
                    this.productCenterImage = require('../assets/pood3.png');
                } else if (newValue === 'Five') {
                    this.productCenterImage = require('../assets/pood5.png');
                } else if (newValue === 'six') {
                    this.productCenterImage = require('../assets/pood5.webp');
                } else if (newValue === 'seven') {
                    this.productCenterImage = require('../assets/pood5.png');
                }
                // 添加您已有的change事件的逻辑代码
            },
        }
    }

</script>

<style scoped>
    .wahaha{
        overflow: hidden;
    }
    .whhimg{
        width: 1.25rem;
    }

    .wahha{
      background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%);
      opacity: 0.8;width: 100%;  position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; 
      line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;word-wrap: break-word;text-align: center;
    }
    .contain /deep/ .t-tabs {
        border: none;
    box-shadow: none;
    }

   



    .contain /deep/ .t-tabs__nav-scroll {}



    .contain /deep/ .t-tabs__nav--card.t-tabs__nav-item {
        padding-right: .1563rem;
        padding-left: .1563rem;
    
     
      
      
    }

    .contain /deep/ .t-tabs__nav--card.t-tabs__nav-item:not(:first-of-type) {
       
        border-left: none;
        border-right: none;
       
    }

    .contain /deep/ .t-tabs__nav-scroll{
        display: flex;justify-content: center;align-items: center;
    }
    .contain /deep/  .t-tabs__nav--card.t-tabs__nav-item.t-is-active {

        border-right-color: #2056c0;
      
       
    }




    .contain /deep/ .t-tabs__nav--card.t-tabs__nav-item.t-is-active {
        border-bottom-color: #2056c0;
       
    }

    .contain /deep/ .t-tabs__nav--card.t-tabs__nav-item:not(:first-of-type) {
        border-left: none;
    }

    .contain /deep/ .t-tabs__nav--card.t-tabs__nav-item.t-is-active {
        border-right-color: #2056c0;
    }

    .contain /deep/ .t-tabs__nav-item.t-size-m{
        font-size: .0938rem;
    }



    /* .page2 {
        background-color: #5B5B5B;
    } */


    h1 {
        color: blue;
    }

    /* .opp /deep/ .t-tabs__nav--card.t-tabs__nav-item.t-is-active{
        border-right-color: #407BF5;
    }
    .opp /deep/ .t-space-vertical {
        border: none;
        box-shadow: none;
    }

    .opp /deep/ .el-tabs__nav-scroll {
        background-color: #E9E9E9;

    } */



    .div1 {
        background-image: url(../assets/PG6.png);
        background-size: contain;
        
        overflow: hidden;
    }

    .div2 {
        background-image: url(../assets/PG7.png)
        ;
        background-size: contain;
        overflow: hidden;
        
    }

    .div3 {
        background-image: url(../assets/PG8.png);
        overflow: hidden;
        background-size: contain;
    }

    .div4 {
        background-image: url(../assets/PG9.png);
        overflow: hidden;
        background-size: contain;
    }

    /* @media screen and (max-width: 1578px) {
        .opp {
            transform: scale(0.92);
        }





    } */

    /* @media screen and (min-width: 1426px) and (max-width: 1510px) {} */
</style>