<template>
  <div class="contain">

    <div
      :style="{ width: '100%', height: '3.3333rem', backgroundImage: 'url(' + require('../assets/fenghai.webp') + ')' , backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat' }">
    </div>
    <!-- 轮播图部分 -->
    <div
      style="width: 100%; height : .6823rem; display: flex; justify-content: center; align-items: center; position: relative;">
      <!-- <img style="margin: 0 auto;position: absolute;" src="../assets/Product Center.png" alt=""> -->

      <img style="margin: 0 auto;position: absolute;height: .1875rem;" src="../assets/关于我们.png" alt="">

      <p
        style="font-size: .1667rem ; color: #5B5B5B ; font-family :  PingFang SC, PingFang SC; font-weight: bold; position: absolute; bottom: .0938rem;">
        关于我们
      </p>

      <p
        style=" display: inline-block ; height: .0104rem; width: .3333rem ; background-color:#407BF5;font-size: .1667rem; color: #5B5B5B; font-family: PingFang SC, PingFang SC; font-weight: bold; position: absolute; bottom: .0521rem;">
      </p>
    </div>


    <div class="oppl"
      style="height:auto;display: flex; justify-content: center; align-items : center ; background-color: #e9e9e9;">
      <div class="tab-container">
        <el-tabs stretch="true" v-model="activeName" type="border-card" @tab-click="handleClick"
          style="height: auto;font-size: .1042rem;">
          <el-tab-pane label="公司简介" name="first">
            <div style="margin-top: .1042rem;"> 
              <img src="../assets/fenghai2.webp" alt="" style="vertical-align: middle;margin-left: .0781rem ; ">
              <span
                style=" font-weight:bold;font-size: .1667rem;font-family: Fontquan-XinYiGuanHeiTi, Fontquan-XinYiGuanHeiTi; color: #407BF5;vertical-align: middle; margin-left: .125rem;">5123导航取五湖之利取四</span>

              <div style="display: flex;justify-content: space-between; align-items: flex-start;position: relative;">

                <div style="position: absolute;height: 2.6042rem;width:100%;top: .2083rem;" class="oDiv"></div>
                <div
                  style="z-index: 999; font-size: .0781rem;font-family: PingFang SC, PingFang SC;color: #5B5B5B; width:3.5rem;height: 1.7813rem;margin-top: .2292rem;margin-left:.2292rem;padding: .25rem .026rem .2708rem .1667rem; box-sizing: border-box; box-shadow: 0 0 .0521rem rgba(0, 0, 0, 0.5);">


                  <p style="text-indent: .0729rem;">

                    <strong>
                      5123导航取五湖之利取四成立于2007年，注册资本7539.1232万元，总部位于广州科学城科技企业加速器，是一家电子通信领域的国家级专精特新“小巨人”高新技术企业。2014年7月15日，5123导航取五湖之利取四在“新三板”挂牌上市（股票代码：830862），旗下拥有一家全资子公司：广州市盛丰软件科技有限公司。
                    </strong>
                  </p>

                  <!-- 需要在 -->

                  <strong>
                    <p style="text-indent: .0729rem;margin-top:.0156rem">
                      5123导航取五湖之利取四主营业务为毫米波雷达、雷达通信模组、雷视融合系统、交通LED显示设备、交通诱导发布及控制系统的研发、生产及销售，公司产品广泛应用于智慧交通、智慧照明及智能家居等多元化场景。
                    </p>
                  </strong>

                  <strong>
                    
                    <p style="text-indent: .0729rem;margin-top: .0156rem">
                      2021年，5123导航取五湖之利取四与西安电子科技大学广州研究院共同成立创新研究中心，在电子通信、毫米波雷达、全息感知、车路协同、边缘计算等领域开展技术研发合作，不断加大创新投入，努力研发国家自主可控的“卡脖子”技术和产品。
                    </p>

                  </strong>
                </div>

                <div
                  style="z-index: 999;width: 2.6927rem;height: 1.7813rem;margin-top: .2292rem;margin-left: .125rem;margin-right: .2292rem;">
                  <img src="../assets/fenghai3.webp" alt="" style="width: 100%;height: 100%;">
                </div>

                <div style="position: absolute; width: .1146rem;height: .7813rem;left: .1146rem;top: .6771rem;">
                  <img src="../assets/fenghai5.webp" alt="" style="width: 100%;height: 100%;">
                </div>
              </div>

              <div style="height: .4167rem;"></div>

            </div>

          </el-tab-pane>


          <el-tab-pane label="文化理念" name="second">
            <div style="margin-top: .1042rem;">
              <img src="../assets/fenghai2.webp" alt="" style="vertical-align: middle;margin-left: .0781rem;">
              <span
                style=" font-weight:bold;font-size: .1667rem;font-family: Fontquan-XinYiGuanHeiTi, Fontquan-XinYiGuanHeiTi; color: #407BF5;vertical-align: middle; margin-left: .125rem;">丰海释义</span>
              
                <div style="width: 100%;height: 1.4583rem;display: flex;justify-content: center;margin-top: .2292rem;">
                <div style="width:6.25rem;height: 1.4583rem;display: flex;" class="fhlg">
                </div>

              </div>

              <p
                style=" font-weight:bold;margin-top: .3646rem; font-size: .1667rem;font-family: Fontquan-XinYiGuanHeiTi, Fontquan-XinYiGuanHeiTi; color: #407BF5;vertical-align: middle; margin-left: .125rem;">
                丰海使命</p>
              <p
                style="text-indent: .1146rem;font-family: PingFang SC-Medium;font-size: .0833rem ; margin-top: .1146rem;">
                致力于成为智能交通领域专业设备制造商与系统集成商 </p>
              <p
                style=" font-weight:bold;margin-top: .3646rem; font-size: .1667rem;font-family: Fontquan-XinYiGuanHeiTi, Fontquan-XinYiGuanHeiTi; color: #407BF5;vertical-align: middle; margin-left: .125rem;">
                经营理念</p>

              <p
                style="text-indent: .1146rem; font-family: PingFang SC-Medium;font-size: .0833rem;margin-top: .1146rem;">
                以客户为中心，以结果为导向。 成就客户成就企业成就员工</p>

              <p
                style=" font-weight:bold;margin-top: .3646rem; font-size: .1667rem;font-family: Fontquan-XinYiGuanHeiTi, Fontquan-XinYiGuanHeiTi; color: #407BF5;vertical-align: middle; margin-left: .125rem;">
                核心价值观</p>

              
                <p
                style="text-indent: .1146rem; font-family: PingFang SC-Medium;font-size: .0833rem;margin-top: .1146rem;">
                人才第一、用心做事、团结协作、勇于担当、蓬勃向上、创新发展</p>
              <div style="height: .4167rem;"></div>

            </div>





            <!-- 
            <div style="line-height: 30px;margin-left: 20px;">
              <div style="width: 100%;border-bottom: #eeeeee solid 1px;color: #2056c0;">丰海释义</div>
              <div style="display: flex;justify-content: space-around; margin-top: 35px;">
                <div style="display: flex;justify-content:  space-around;align-items: center;">
                  <div style="width: 85px;height:65px ;background-color: #8faadf;text-align: center;line-height: 65px;">
                    丰</div>
                  丰硕成果,丰功伟业
                </div>

                <div style="display: flex;justify-content:  space-around;align-items: center;">
                  <div style="width: 85px;height:65px ;background-color: #DD9CA1;text-align: center;line-height: 65px;">
                    海</div>
                  海纳百川,百川汇海
                </div>
              </div>
              <div style="display: flex;justify-content: space-between;margin-top: 80px;">
                <img src="../assets/1259.jpg" alt="">

              </div>

              <div style="width: 100%;border-bottom: #eeeeee solid 1px;color: #2056c0;margin-top: 50px;">丰海使命</div>
              <div style="display: flex;">
                <p style="height: 300px;line-height: 300px;color: #999;font-size: 16px;">致力于成为智能交通领域专业设备制造商与系统集成商</p>
              </div>

              <div style="width: 100%;border-bottom: #eeeeee solid 1px;color: #2056c0;">经营理念</div>
              <div style="display: flex;">
                <p style="height: 300px;line-height: 300px;color: #999;font-size: 16px;">以客户为中心，以结果为导向。
                  成就客户成就企业成就员工
                </p>
              </div>

              <div style="width: 100%;border-bottom: #eeeeee solid 1px;color: #2056c0;">核心价值观</div>
              <div style="display: flex;">
                <p style="height: 300px;line-height: 300px;color: #999;font-size: 16px;">人才第一、用心做事、团结协作、勇于担当、蓬勃向上、创新发展
                </p>
              </div>

            </div> -->
          </el-tab-pane>


          <el-tab-pane label="研究中心" name="eight">

            <div style="margin-top: .1042rem;padding: .1042rem;">

              <div style="display:flex ; justify-content: center;height: 0.70rem;;margin-right: .1042rem;">
                <p style="width:50%;font-size: .2604rem;color: red;font-weight: bold;font-family: '微软雅黑';">
                  5123导航取五湖之利取四—西电广研院智慧交通创新研究中心
                </p>
                <p style="width: 51%;margin-left: .1042rem;">
                  <img src="../assets/xd5.webp" alt="" style="width:100%;">
                </p>

              </div>

              <p style="border-bottom: .0313rem solid #2056c0;height: .0938rem;width: 100%;"></p>

              <p style="margin-top: .0052rem;border-bottom: .0104rem solid #2056c0;height: .026rem;width: 100%;"></p>


              <div style="width:100%;margin-top: .1563rem;height: 1.8854rem;display: flex;justify-content: center;">
                <div style="width:48%;position: relative;">

                  <p
                    style="font-size: .0833rem;text-indent: .1458rem;font-family: PingFang SC, PingFang SC;line-height: .125rem;">
                    5123导航取五湖之利取四-西电广州研究院智慧交通创新研究中心，是在《交通强国建设纲要》《国家综合立体交通网规划纲要》的政策背景下，根据“面向国家战略需求、面向世界科技前沿”方针，由5123导航取五湖之利取四、西安电子科技大学广州研究院联合成立。
                  </p>

                  <p
                    style="font-size: .0833rem;text-indent: .1458rem;font-family: PingFang SC, PingFang SC;line-height: .125rem;">
                    研究中心实验室设立在西电广州研究院。研究中心围绕科学研究、成果转化及人才培养，重点打造智慧交通技术研发、专业人才培养、产业产品培育和公共技术四大平台，在智慧高速、车路协同、全息感知、边缘计算、人工智能、软件工程以及通信工程等交叉领域进行科技研发，探索国家自主可控的“卡脖子”的技术和产品，开展具有国际国内影响力的产业学术交流活动，支持省市地方高新技术战略部署及产业规划。致力于成为国家级的智慧交通创新研究中心，努力推动智慧交通核心技术的突破发展和智慧交通产业高质量转型升级。
                  </p>

                  <P style="position: absolute;bottom: -0.0781rem ; width: 100%;">
                    <img src="../assets/xd1.webp" alt="" style="width: 100%;">

                  </P>
                </div>

                <div style="width:2.9271rem;height: 1.9063rem;margin-left: .1042rem;">
                  <img src="../assets/xd.webp" alt="" style="width: 100%;height: 100%;">

                </div>

              </div>


              <div style="width: 100%;margin-top: .2604rem;">

                <img src="../assets/xd2.webp" alt="" style="width: 100%;">

              </div>

              <div style="width: 100%;">

                <img src="../assets/xd3.webp" alt="" style="width:100%">

              </div>





              <div style="height: .3125rem;"></div>
            </div>




          </el-tab-pane>



          <!--  -->

          <el-tab-pane label="荣誉资质" name="third">

            <el-tabs type="border-card">

              <el-tab-pane label="荣誉资质">

                <div class="div1-container" :class="{ 'hidden': currentPage !== 1 }">
                  <div style="display: flex;justify-content: center;width: 100%;margin-top: .4167rem;">

                    <div style="width:1.9063rem;height: 1.3854rem;text-align: center;">
                      <img :src="imagePaths['413001']" alt="" style="max-width: 100%; max-height: 100%;"
                        @dblclick="showImage('413001')">
                      <p
                        style="width:1.9063rem;text-align: center;font-size: .1302rem;font-family:'微软雅黑' ;color:black;">
                        国家级专精特新小巨人企业
                      </p>
                    </div>
                    <div style="width:1.9063rem;height: 1.3854rem;margin-left: .125rem;text-align: center;">
                      <img :src="imagePaths['413002']" alt="" style="max-width: 100%; max-height: 100%;"
                        @dblclick="showImage('413002')">
                      <p
                        style="width:1.9063rem;text-align: center;font-size:.1302rem;font-family:'微软雅黑' ;color: black;">
                        广东省守合同重信用企业
                      </p>
                    </div>
                    <div style="width:1.9063rem;height: 1.3854rem;margin-left: .125rem;text-align: center;">
                      <img :src="imagePaths['413003']" alt="" style="max-width: 100%; max-height: 100%;"
                        @dblclick="showImage('413003')">
                      <p
                        style="width:1.9063rem;text-align: center;font-size: .1302rem;font-family:'微软雅黑' ;color:black ;">
                        企业研发机构证书</p>
                    </div>

                  </div>



                  <div
                    style="display: flex;justify-content: center;width: 100%;margin-top: .4167rem;text-align: center;">
                    <div style="width:1.9063rem;height: 1.3854rem;">
                      <img :src="imagePaths['413004']" alt="" style="max-width: 100%; max-height: 100%;"
                        @dblclick="showImage('413004')">
                      <p
                        style="width:1.9063rem;text-align: center;font-size: .1302rem;font-family:'微软雅黑' ;color: black;">
                        高新技术企业</p>
                    </div>
                    <div style="width:1.9063rem;height: 1.3854rem;margin-left: .125rem;text-align: center;">
                      <img :src="imagePaths['413005']" alt="" style="max-width: 100%; max-height: 100%;"
                        @dblclick="showImage('413005')">
                      <p
                        style="width:1.9063rem;text-align: center;font-size:.1302rem;font-family:'微软雅黑' ;color: black;">
                        广东省工程技术研究中心
                      </p>
                    </div>
                    <div style="width:1.9063rem;height: 1.3854rem;margin-left: .125rem;text-align: center;">
                      <img :src="imagePaths['413006']" alt="" style="max-width: 100%; max-height: 100%;"
                        @dblclick="showImage('413006')">
                      <p
                        style="width:1.9063rem;font-family:'微软雅黑' ; text-align: center;font-size:.1302rem;color: black;">
                        广州市科技小巨人企业
                      </p>
                    </div>

                  </div>


                </div>

                <div class="div1-container" :class="{ 'hidden': currentPage !== 2 }">


                  <div style="display: flex;justify-content: center;width: 100%;margin-top: .4167rem;">

                    <div style="width:1.1979rem;height: 2.0833rem;">
                      <img :src="imagePaths['433101']" alt="" style="max-width: 100%; max-height: 100%;"
                        @dblclick="showImage('433101')">
                      <p
                        style="width:1.1979rem;text-align: center;font-size: .1302rem;font-family:'微软雅黑' ;color: black;">
                        广东新三板公司协会副会长单位</p>
                    </div>
                    <div style="width:1.1979rem;height: 2.0833rem;margin-left: .5208rem;">
                      <img :src="imagePaths['433102']" alt="" style="max-width: 100%; max-height: 100%;"
                        @dblclick="showImage('433102')">
                      <p
                        style="width:1.1979rem;text-align: center;font-family:'微软雅黑' ;color: black;font-size:  .1302rem;">
                        广州开发区2020年度瞪羚企业20强</p>
                    </div>
                    <div style="width:1.1979rem;height: 2.0833rem;margin-left: .5208rem;">
                      <img :src="imagePaths['433103']" alt="" style="max-width: 100%; max-height: 100%;"
                        @dblclick="showImage('433103')">
                      <p
                        style="width:1.1979rem;text-align: center;font-family:'微软雅黑' ;color: black;font-size:  .1302rem;">
                        广东省名牌产品</p>
                    </div>

                  </div>
                  <!-- 这里放第二页的内容 -->
                </div>
                <div class="div1-container" :class="{ 'hidden': currentPage !== 3 }">
                  <!-- 这里放第三页的内容 -->
                </div>
                <div style="position: absolute;background-color: red;right: 0;bottom:0;">
                  <!-- <img src="../assets/00021.png" alt=""> -->
                </div>

                <div class="pagination"
                  style="margin-top:.5208rem;margin-bottom: .2604rem;position: relative;width: 100%;">



                  <div style="position: absolute; display: flex;justify-content: center;">


                    <img @click="prevPage" src="../assets/leftt1.png"
                      style="cursor: pointer;width:.0781rem;height: .0781rem;transform: translateY(.0365rem);">
                    <div class="inlineDiv">
                      <img src="left_arrow.png" alt="" @click="changePage('prev')">
                      <span :class="{ 'active': currentPage === 1 }" @click="changePage(1)"
                        style="width: .1563rem;height: .1563rem;display: inline-block;">1</span>
                      <span :class="{ 'active': currentPage === 2 }" @click="changePage(2)"
                        style="width: .1563rem;height: .1563rem;display: inline-block;">2</span>
                      <span :class="{ 'active': currentPage === 3 }" @click="changePage(3)"
                        style="width: .1563rem;height: .1563rem;display: inline-block;">3</span>
                      <img src="right_arrow.png" alt="" @click="changePage('next')">
                    </div>

                    <img @click="nextPage" src="../assets/rightt2.png"
                      style="cursor: pointer;width: .0781rem;height: .0781rem;transform: translateY(.0365rem);">

                  </div>

                </div>




              </el-tab-pane>

              <el-tab-pane label="体系认证">

                <div class="div2-container" :class="{ 'hidden': currentPagea !== 1 }">
                  <div style="display: flex;justify-content: center;width: 100%;margin-top: .4167rem;">

                    <div style="width:1.8229rem;height: 1.9063rem;">
                      <img :src="imagePaths['tixi1']" alt="" style="max-width: 100%; max-height: 100%;"
                        @dblclick="showImage('tixi1')">
                      <p
                        style="width:1.4063rem;color:#fff;background-color: #407BF5;height: .2604rem;line-height: .2604rem; text-align: center;font-size: .1042rem;font-weight: bold;">
                        环境管理体系认证证书</p>
                    </div>
                    <div style="width:1.8229rem;height: 1.9063rem;margin-left: .125rem;">
                      <img :src="imagePaths['tixi2']" alt="" style="max-width: 100%; max-height: 100%;"
                        @dblclick="showImage('tixi2')">
                      <p
                        style="width:1.4063rem;color:#fff;background-color: #407BF5;height: .2604rem;line-height: .2604rem; text-align: center;font-size: .1042rem;font-weight: bold;">
                        职业健康安全管理体系认证证书</p>
                    </div>
                    <div style="width:1.8229rem;height: 1.9063rem;margin-left: .125rem;">
                      <img :src="imagePaths['tixi3']" alt="" style="max-width: 100%; max-height: 100%;"
                        @dblclick="showImage('tixi3')">
                      <p
                        style="width:1.4063rem;color:#fff;background-color: #407BF5;height: .2604rem;line-height: .2604rem; text-align: center;font-size: .1042rem;font-weight: bold;">
                        中国国家强制性产品认证证书</p>
                    </div>

                  </div>



                  <div style="display: flex;justify-content: center;width: 100%;margin-top: .4167rem;">
                    <div style="width:1.8229rem;height: 1.9063rem;">
                      <img :src="imagePaths['tixi4']" alt="" style="max-width: 100%; max-height: 100%;"
                        @dblclick="showImage('tixi4')">
                      <p
                        style="width:1.4063rem;color:#fff;background-color: #407BF5;height: .2604rem;line-height: .2604rem; text-align: center;font-size: .1042rem;font-weight: bold;">
                        信息安全管理体系认证证书</p>
                    </div>
                    <div style="width:1.8229rem;height: 1.9063rem;margin-left: .125rem;">
                      <img :src="imagePaths['tixi5']" alt="" style="max-width: 100%; max-height: 100%;"
                        @dblclick="showImage('tixi5')">
                      <p
                        style="width:1.4063rem;color:#fff;background-color: #407BF5;height: .2604rem;line-height: .2604rem; text-align: center;font-size: .1042rem;font-weight: bold;">
                        交通产品认证证书</p>
                    </div>
                    <div style="width:1.8229rem;height: 1.9063rem;margin-left: .125rem;">
                      <img :src="imagePaths['tixi6']" alt="" style="max-width: 100%; max-height: 100%;"
                        @dblclick="showImage('tixi6')">
                      <p
                        style="width:1.4063rem;color:#fff;background-color: #407BF5;height: .2604rem;line-height: .2604rem; text-align: center;font-size: .1042rem;font-weight: bold;">
                        中国节能认证证书</p>
                    </div>

                  </div>




                </div>

                <div class="div2-container" :class="{ 'hidden': currentPagea !== 2 }">
                  <div style="display: flex;justify-content: center;width: 100%;margin-top: .4167rem;">

                    <div style="width:1.8229rem;height: 1.9063rem;">
                      <img :src="imagePaths['tixi7']" alt="" style="max-width: 100%; max-height: 100%;"
                        @dblclick="showImage('tixi7')">
                      <p
                        style="width:1.4063rem;color:#fff;background-color: #407BF5;height: .2604rem;line-height: .2604rem; text-align: center;font-size: .1042rem;font-weight: bold;">
                        质量管理体系认证证书</p>
                    </div>
                    <div style="width:1.8229rem;height: 1.9063rem;margin-left: .125rem;">
                      <img :src="imagePaths['tixi8']" alt="" style="max-width: 100%; max-height: 100%;"
                        @dblclick="showImage('tixi8')">
                      <p
                        style="color:#fff;width:1.4063rem;background-color: #407BF5;height: .2604rem;line-height: .2604rem; text-align: center;font-size: .1042rem;font-weight: bold;">
                        毫米波雷达检测报告</p>
                    </div>
                    <div style="width:1.8229rem;height: 1.9063rem;margin-left: .125rem;">
                      <img :src="imagePaths['tixi10']" alt="" style="max-width: 100%; max-height: 100%;"
                        @dblclick="showImage('tixi10')">
                      <p
                        style="width:1.4063rem;color:#fff;background-color: #407BF5;height: .2604rem;line-height: .2604rem; text-align: center;font-size: .1042rem;font-weight: bold;">
                        知识产权管理体系认证证书</p>
                    </div>

                  </div>








                </div>
                <div class="div2-container" :class="{ 'hidden': currentPagea !== 3 }">
                  <!-- 这里放第三页的内容 -->
                </div>
                <div style="position: absolute;background-color: red;right: 0;bottom:0;">
                  <!-- <img src="../assets/00021.png" alt=""> -->
                </div>




                <div class="pagination"
                  style="margin-top:.5208rem;margin-bottom: .2604rem;position: relative;width: 100%;">



                  <div style="position: absolute; display: flex;justify-content: center;">


                    <img @click="prevPagea" src="../assets/leftt1.png"
                      style="cursor: pointer;width:.0781rem;height: .0781rem;transform: translateY(.0365rem);">
                    <div class="inlineDiv">

                      <span :class="{ 'active': currentPagea === 1 }" @click="changePagea(1)"
                        style="width: .1563rem;height: .1563rem;display: inline-block;">1</span>
                      <span :class="{ 'active': currentPagea === 2 }" @click="changePagea(2)"
                        style="width: .1563rem;height: .1563rem;display: inline-block;">2</span>
                      <span :class="{ 'active': currentPagea === 3 }" @click="changePagea(3)"
                        style="width: .1563rem;height: .1563rem;display: inline-block;">3</span>

                    </div>

                    <img @click="nextPagea" src="../assets/rightt2.png"
                      style="cursor: pointer;width: .0781rem;height: .0781rem;transform: translateY(.0365rem);">

                  </div>

                </div>




              </el-tab-pane>






              <div v-if="showModal" class="modal">
                <div class="modal-content">
                  <img :src="require(`@/assets/${modalImageName}.png`)" alt=""
                    style="max-width: 100%; max-height: 100%;">
                  <span class="close" @click="closeModal">&times;</span>
                </div>
              </div>
            </el-tabs>
          </el-tab-pane>


          <!-- <el-tab-pane label="新闻中心" name="four">
            <router-view></router-view>
     
         
          </el-tab-pane> -->

          <el-tab-pane label="发展历程" name="Five">

            <div style="display: flex;justify-content: space-between;margin-bottom: .2604rem;">
              <!-- <img src="../assets/licheng.jpg" alt=""> -->
            </div>
            <!-- <div class="year" style="width: 210px;margin-bottom: 60px;">
              <b style="animation: blink 1.6s infinite;">2024</b>年
            </div> -->



            <div class="year" style="width: 1.0938rem;"><b>2023</b>年</div>
            <div
              style="text-indent: .1302rem;line-height: .2083rem;font-size: .0833rem;color: #666;margin-bottom: .1563rem;">
              <p> 5月，雷视融合系统研发成功并投入应用</p>
              <p>6月，公司连续八年进入新三板创新层</p>
              <p>7月，公司获得国家级专精特新“小巨人”企业称号</p>
              <p>10月，成功研发新产品——433通讯模块</p>
              <p>12月，与美的集团达成合作意向，成为美的供应商</p>
              <p>12月，通过高新技术企业第四次认定</p>
            </div>


            <div class="year" style="width: 1.0938rem;"><b>2022</b>年</div>
            <div
              style="text-indent: .1302rem;line-height: .2083rem;font-size: .0833rem;color: #666;margin-bottom: .1563rem;">
              <p>6月，成功研发新产品——毫米波雷达 </p>
              <p>9月，获得广州市黄埔区、广州开发区2022年度绿色企业认定</p>

              <p>11月，通过两化融合管理体系评定</p>
            </div>

            <div class="year" style="width: 1.0938rem;"><b>2021</b>年</div>
            <div
              style="text-indent: .1302rem;line-height: .2083rem;font-size: .0833rem;color: #666;margin-bottom: .1563rem;">
              <p>8月,获得黄埔区广州开发区2020年瞪羚企业20强 </p>
              <p>11月,5123导航取五湖之利取四-西电广州研究院智慧交通研究中心正式签约揭牌</p>

            </div>



            <div class="year" style="width: 1.0938rem;"><b>2020</b>年</div>
            <div
              style="text-indent: .1302rem;line-height: .2083rem;font-size: .0833rem;color: #666;margin-bottom: .1563rem;">
              <p>6月,公司连续五年进入新三板创新层 </p>
              <p>12月,公司通过高新技术企业第三次认定</p>

            </div>


            <div class="year" style="width: 1.0938rem;"><b>2019</b>年</div>
            <div
              style="text-indent: .1302rem;line-height: .2083rem;font-size: .0833rem;color: #666;margin-bottom: .1563rem;">
              <p>公司连续四年进入新三板创新层 </p>
              <p>"可变信息标志控制软件"等产品被认定为广东省高新技术产品</p>
            </div>

            <div class="year" style="width: 1.0938rem;"><b>2018</b>年</div>
            <div
              style="text-indent: .1302rem;line-height: .2083rem;font-size: .0833rem;color: #666;margin-bottom: .1563rem;">
              <p>"LED显示屏"被广东省名牌产品推进委员会评为广东省名牌产品 </p>
            </div>

            <div class="year" style="width: 1.0938rem;"><b>2017</b>年</div>
            <div
              style="text-indent: .1302rem;line-height: .2083rem;font-size: .0833rem;color: #666;margin-bottom: .1563rem;">
              <p>3月，成功研发新产品——智能收费亭 </p>
              <p>5月，成立海外业务部 </p>
              <p>9月，被广东省科学技术厅认定为广东省工程技术研究中心 </p>
            </div>

            <div class="year" style="width: 1.0938rem;"><b>2016</b>年</div>
            <div
              style="text-indent: .1302rem;line-height: .2083rem;font-size: .0833rem;color: #666;margin-bottom: .1563rem;">
              <p>1月，被认定为广州开发区2015年度瞪羚企业 </p>
              <p>3月，“手持机软件V1.0”获得计算机软件著作权登记证书</p>
              <p>3月，参加南京第十八届“中国高速公路信息化研讨会暨技术产品展示会”</p>

              <p>6月，首批进入新三板创新层</p>
              <p>7月，广州开发区管委会秘书长陈建荣一行莅临公司参观指导</p>
              <p>7月，荣获新三板最具价值投资百强奖</p>
              <p>8月，获得实用新型专利证书</p>

              <p>8月，完成重大资产重组</p>
              <p>9月，LED情报板在智能交通系统中的应用被确认为广州市科学技术成果</p>
              <p>12月，“基于电力载波的LED显示屏”与“LED可变信息情报板”产品被认定为广东省高新技术产品</p>
            </div>

            <div class="year" style="width: 1.0938rem;"><b>2015</b>年</div>
            <div
              style="text-indent: .1302rem;line-height: .2083rem;font-size: .0833rem;color: #666;margin-bottom: .1563rem;">
              <p>2月，获得商标注册证</p>
              <p>3月，参加南昌第十七届“中国高速公路信息化研讨会暨技术产品展示会”</p>
              <p>3月，进行第一次股权激励</p>

              <p>4月，通过ISO9001：2008质量管理体系认证</p>
              <p>4月，被认定为高新技术企业</p>
              <p>8月，获得商标注册证</p>
              <p>9月，通过环境管理体系认证、职业健康安全管理体系认证</p>
              <p>9月，参加广东新三板公司协会第一届会员大会，并当选为副会长单位</p>
              <p>同年被广州市科创委认定为广州市企业研究开发机构</p>
            </div>

            <div class="year" style="width: 1.0938rem;"><b>2014</b>年</div>
            <div
              style="text-indent: .1302rem;line-height: .2083rem;font-size: .0833rem;color: #666;margin-bottom: .1563rem;">
              <p>1月，被认定为滁州学院毕业生实习就业基地</p>
              <p>1月，顺利完成股份制改造，整体更名为5123导航取五湖之利取四</p>
              <p>3月，参加青岛第十六届“中国高速公路信息化研讨会暨技术产品展示会”</p>
              <p>4月，工厂牵至广州开发区开源大道11号科技企业加速器</p>
              <p>5月，通过3C认证</p>
              <p>6月，获得广东省守合同重信用企业</p>
              <p>7月，正式在“全国中小企业股份转让系统”挂牌上市，证券简称：5123导航取五湖之利取四 证券代码：830862</p>

            </div>




            <div class="year" style="width: 1.0938rem;"><b>2013</b>年</div>
            <div
              style="text-indent: .1302rem;line-height: .2083rem;font-size: .0833rem;color: #666;margin-bottom: .1563rem;">
              <p>4月，参加合肥第十五届中国高速公路信息化研讨会和信息化技术产品展示会</p>
              <p>10月，启动“新三板”上市</p>
              <p>11月，高速公路大型可变情报板签约项目突破100个</p>
              <p>12月，获得软件产品登记证书</p>

            </div>

            <div class="year" style="width: 1.0938rem;"><b>2012</b>年</div>
            <div
              style="text-indent: .1302rem;line-height: .2083rem;font-size: .0833rem;color: #666;margin-bottom: .1563rem;">
              <p>高速公路光平台业务成功签约青海项目</p>
              <p>高速公路大型可变情报板签约项目突破50个</p>

            </div>
            <div class="year" style="width: 1.0938rem;"><b>2011</b>年</div>
            <div
              style="text-indent: .1302rem;line-height: .2083rem;font-size: .0833rem;color: #666;margin-bottom: .1563rem;">
              <p>大型LED可变情报板成功签约高速公路项目</p>
              <p>高速公路全业务数字视频光传输交换平台研发立项并取得技术突破</p>

            </div>
            <div class="year" style="width: 1.0938rem;"><b>2010</b>年</div>
            <div
              style="text-indent: .1302rem;line-height: .2083rem;font-size: .0833rem;color: #666;margin-bottom: .1563rem;">
              <p>大型LED可变情报板项目正式立项并研发成功</p>

            </div>

            <div class="year" style="width: 1.0938rem;"><b>2009</b>年</div>
            <div
              style="text-indent: .1302rem;line-height: .2083rem;font-size: .0833rem;color: #666;margin-bottom: .1563rem;">
              <p>研发成功高速公路收费站费额显示器</p>
              <p>研发成功LED隧道诱导系统、隧道紧急疏散标志</p>

            </div>

            <div class="year" style="width: 1.0938rem;"><b>2008</b>年</div>
            <div
              style="text-indent: .1302rem;line-height: .2083rem;font-size: .0833rem;color: #666;margin-bottom: .1563rem;">
              <p>研发成功高速公路车道指示标志、交通信息灯</p>

            </div>

            <div class="year" style="width: 1.0938rem;"><b>2007</b>年</div>
            <div
              style="text-indent: .1302rem;line-height: .2083rem;font-size: .0833rem;color: #666;margin-bottom: .1563rem;">
              <p>广州市丰海信息科技有限公司注册成立</p>

            </div>

          </el-tab-pane>

          <el-tab-pane label="组织架构" name="six">

            <div class="zuzhi"
              style="display: flex;justify-content: space-between;margin-top: .2604rem;margin-bottom: .2604rem;width: 100%;">
              <img src="../assets/zzjg.webp" alt="" style="width: 100%;">
            </div>


          </el-tab-pane>

        </el-tabs>
      </div>


    </div>


  </div>
</template>

<script>
  export default {

    data() {
      return {
        showModal: false,
        modalImageName: '',
        currentPage: 1,
        currentPagea: 1,
        totalPages: 3, // 总页数，根据实际情况修改,
        imagePaths: {
          '413001': require('@/assets/413001.webp'),
          '413002': require('@/assets/413002.webp'),
          '413003': require('@/assets/413003.webp'),
          '413004': require('@/assets/413004.webp'),
          '413005': require('@/assets/413005.webp'),
          '413006': require('@/assets/413006.webp'),
          '433101': require('@/assets/433101.webp'),
          '433102': require('@/assets/433102.webp'),
          '433103': require('@/assets/433103.webp'),
          'tixi1': require('@/assets/tixi1.webp'),
          'tixi2': require('@/assets/tixi2.webp'),
          'tixi3': require('@/assets/tixi3.webp'),
          'tixi4': require('@/assets/tixi4.webp'),
          'tixi5': require('@/assets/tixi5.webp'),
          'tixi6': require('@/assets/tixi6.webp'),
          'tixi7': require('@/assets/tixi7.webp'),
          'tixi8': require('@/assets/tixi8.webp'),
          'tixi9': require('@/assets/tixi9.webp'),
          'tixi10': require('@/assets/tixi10.webp'),
          '00024': require('@/assets/00024.png'),
          '00026': require('@/assets/00026.png'),
          '00027': require('@/assets/00027.png'),
          '00028': require('@/assets/00028.png'),


        },

        activeName: 'first',
        tableData: [{
          date: '2024-01-15',
          name: '转型升级，打通上下游产业链',

        }, {
          date: '2024-01-15',

          name: '5123导航取五湖之利取四“梅开二度” 入选2017新三板创新层'
        },
        {
          date: '2024-01-15',

          name: '5123导航取五湖之利取四荣获新三板最具价值投资百强奖'
        }],



      };
    },
    methods: {
      changePage(page) {
        if (page === 'prev' && this.currentPage > 1) {
          this.currentPage--;
        } else if (page === 'next' && this.currentPage < 3) {
          this.currentPage++;
        } else if (typeof page === 'number' && page >= 1 && page <= 3) {
          this.currentPage = page;
        }
      },
      changePagea(page) {
        if (page === 'prev' && this.currentPage > 1) {
          this.currentPagea--;
        } else if (page === 'next' && this.currentPage < 3) {
          this.currentPagea++;
        } else if (typeof page === 'number' && page >= 1 && page <= 3) {
          this.currentPagea = page;
        }
      },
      prevPage() {
        if (this.currentPage > 1) {
          this.currentPage--;
        }
      },
      nextPage() {
        if (this.currentPage < this.totalPages) {
          this.currentPage++;
        }


      },
      prevPagea() {
        if (this.currentPagea > 1) {
          this.currentPagea--;
        }

      },
      nextPagea() {
        if (this.currentPagea < this.totalPages) {
          this.currentPagea++;
        }


      },
      handleTabChange(tab) {
        if (tab === 'first') {
          this.activeName = 'first'; // 设置第一个选项卡为激活状态
        } else if (tab === 'second') {
          this.activeName = 'second'; // 设置第二个选项卡为激活状态
        } else if (tab === 'third') {
          this.activeName = 'third'; // 设置第三个选项卡为激活状态
        }

        else if (tab === 'Five') {
          this.activeName = 'Five'; // 设置第三个选项卡为激活状态
        }
        else if (tab === 'six') {
          this.activeName = 'six'; // 设置第三个选项卡为激活状态
        } else if (tab === 'eight') {
          this.activeName = 'eight'; // 设置第三个选项卡为激活状态
        }

        //

        // console.log(tab); // 
      },
      showImage(imageName) {
        this.modalImageName = imageName;
        this.showModal = true;
      },
      closeModal() {
        this.showModal = false;
      }
    },
    // mounted() {
    //   // 在组件挂载完成后，将活动标签设置为第一个标签的name属性值
    //   const tab = this.$route.query.tab;
    //   if (tab === 'first') {
    //     this.activeName = 'first'; // 设置第一个选项卡为激活状态
    //   } else if (tab === 'second') {
    //     this.activeName = 'second'; // 设置第二个选项卡为激活状态
    //   } else if (tab === 'third') {
    //     this.activeName = 'third'; // 设置第三个选项卡为激活状态
    //   }
    //   else if (tab === 'four') {
    //     this.activeName = 'four'; // 设置第三个选项卡为激活状态
    //   }
    //   else if (tab === 'Five') {
    //     this.activeName = 'Five'; // 设置第三个选项卡为激活状态
    //   }
    //   else if (tab === 'six') {
    //     this.activeName = 'six'; // 设置第三个选项卡为激活状态
    //   }

    // },
    mounted() {
      this.handleTabChange(this.$route.query.tab);
    },
    watch: {
      '$route.query.tab': {
        immediate: true,
        handler(newTab) {
          this.handleTabChange(newTab);
        }
      }
    },


  };

</script>
<style>
  @keyframes blink {
    0% {
      opacity: 1;
    }

    25% {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    75% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
</style>

<style scoped>
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: .1042rem;
  }

  .pagination button {
    margin: 0 .0521rem;
  }

  .oDiv {
    background-image: url(../assets/fegnhai4.png);
    position: relative;
    background-position: -0.026rem -0.0521rem;

  }

  .about-us {
    max-width: 6.25rem;
    margin: 0 auto;
    padding: .2083rem;
  }

  .banner {
    text-align: center;
    margin-bottom: .2083rem;
  }

  .banner-title {
    font-size: .1875rem;
    font-weight: bold;
    color: #333;
  }

  .inlineDiv span {
    width: .1563rem;
    height: .1563rem;
    display: inline-block;
    text-align: center;
    line-height: .1563rem;
    margin-left: .0781rem;
    margin-right: .0781rem;
    border-radius: 10%;


    font-weight: bold;
  }

  .active {
    background-color: #407BF5;
    /* 设置选中页面的背景颜色为蓝色 */
    width: .2604rem;
    height: .2604rem;
    color: #fff;
    font-weight: bold;
  }


  .banner-image {
    width: 100%;
    max-height: 2.0833rem;
    object-fit: cover;
    border-radius: .0417rem;
  }

  .content {
    margin-bottom: .2083rem;
  }

  .section {
    margin-bottom: .1042rem;
  }


  .modal {
    display: block;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }


  .modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 4.1667rem;
    max-height: 80%;
    background-color: rgba(0, 0, 0, 0);
    padding: .1042rem;
  }


  .close {
    position: absolute;
    top: -0.0521rem;
    right: -0.0521rem;
    font-size: .1563rem;
    font-weight: bold;
    color: white;
    cursor: pointer;
  }


  .section-title {
    font-size: .125rem;
    font-weight: bold;
    color: #333;
  }


  .section-text {
    font-size: .0833rem;
    color: #555;
  }


  .team-title {
    font-size: .125rem;
    font-weight: bold;
    color: #333;
    margin-bottom: .1042rem;
  }


  .team-members {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }


  .team-member {
    display: flex;
    align-items: center;
    margin: .0521rem;
  }


  .no-save {
    -webkit-touch-callout: none;
    /* 禁止长按图片弹出菜单(iOS) */
    -webkit-user-select: none;
    /* 禁止选择文本和图片(iOS/Chrome/Safari) */
    -khtml-user-select: none;
    /* 禁止选择文本和图片(Konqueror) */
    -moz-user-select: none;
    /* 禁止选择文本和图片(Firefox) */
    -ms-user-select: none;
    /* 禁止选择文本和图片(IE/Edge) */
    user-select: none;
    /* 禁止选择文本和图片(Chrome/Safari/Opera) */

  }

  .member-avatar {
    width: .4167rem;
    height: .4167rem;
    object-fit: cover;
    border-radius: 50%;
  }

  .member-details {
    margin-left: .0521rem;
  }

  .member-name {
    font-size: .0833rem;
    font-weight: bold;
    color: #333;
  }

  .member-role {
    font-size: .0729rem;
    color: #555;
  }

  .year {
    height: .2083rem;
    line-height: .2083rem;
    font-size: .0833rem;
    border-bottom: #eee solid .0052rem;
    width: .8854rem;
    padding-left: .125rem;
  }

  .year b {
    font-size: .1563rem;
    color: #bb3944;
    font-weight: bold;
  }

  .el-tabs__item {
    width: 1.5625rem;
    /* 设置每个el-table-pane的宽度 */
  }

  .el-tabs.el-tabs--top {
    width: 6.25rem;
  }

  #tab-first .el-tabs__item.is-top {
    width: 1.5625rem;
  }

  .tab-container /deep/ .el-tabs__nav {
    background-color: #E9E9E9;


  }

  .hidden {
    display: none;
  }

  .contain /deep/ .el-tabs__nav-scroll {
    background-color: #fff;


  }

  .contain /deep/ .el-tabs__item.is-active {
    padding: 0 .2083rem 0 .2083rem;
  }


  .tab-container /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
    border-right-color: #2056c0;

  }

  .tab-container /deep/ .el-tabs {

    border: none;
    box-shadow: none;

  }

  .tab-container /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item {
    font-size: .1042rem;
    font-family: 'HarmonyOS_Sans_SC_Regular';

  }



  .contain /deep/ .tab-container {
    border: none;
    box-shadow: none;

  }

  .tab-container {
    margin-top: .0781rem;
    margin-bottom: .0781rem;
  }

  .fhlg {
    background-image: url(../assets/fenghai16.webp);
    background-size: contain;
  }
</style>