<template>
    <div>
        <div style="width: 1250px;margin:0 auto;transform: translateY(-30px) translateX(-20px);">

            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item
                    :to="{ path: '/casePresentation', query: { tab: 'nine' }}">案例展示</el-breadcrumb-item>
                <el-breadcrumb-item :to="({ path: '/casePresentation', query: { tab: 'nine' } })">收费车道系列</el-breadcrumb-item>
            </el-breadcrumb>
        </div>


        <div style="width: 100%; margin-top: 10px;  display: flex; justify-content: center; align-items: center;">
            
            <div style="width:100%;  display:flex;justify-content: center;">
                <img src="../../assets/2收费车道.webp" alt="" style="width: 600px;border-radius: 50px;">
                <!-- <p style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%);opacity: 0.8;
                   width: 100%; height: 40px; position: absolute;bottom: 0;font-size: 18px;color: #FFFFFF; line-height: 40px;
                   font-weight: 400;padding-left: 10px;box-sizing: border-box;">智能收费亭</p> -->
            </div>

        </div>

        <div style="width:1250px;margin: 0 auto;padding-bottom: 15px;border-bottom: #eee solid 1px;">
        </div>

        <div style="display: flex;justify-content: center;margin-top: 25px;margin-bottom: 25px; ">
            <el-button @click="goBack">返回列表</el-button>
        </div>

    </div>




</template>


<script>

    export default {
        data() {
            return {

            }
        },

        methods: {
            goBack() {
                this.$router.push({ path: '/casePresentation', query: { tab: 'nine' }});
            },
            
        },
    }

</script>

<style scoped>

</style>