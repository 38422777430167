<template>
    <div>
        <div style="width: 6.5104rem;margin: 0 auto;">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/product-center'}">产品中心</el-breadcrumb-item>
                <el-breadcrumb-item
                    :to="{ path: '/product-center', query: { tab: 'four' }}">LED显示标志系列</el-breadcrumb-item>
                <el-breadcrumb-item>LED诱导系统</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div style="display: flex;justify-content: center;margin-top: .4167rem;">
            <div>
                <img src="../../assets/048.webp" alt="" style="height: 1.1198rem;">

            </div>
            <div style="width:2.7344rem;margin-left: .2604rem;position: relative;">
                <p class="name">LED诱导系统</p>
                <P class="content" style="text-indent: .1198rem;font-size: .0833rem">LED诱导系统是一种基于LED技术的交通指示系统，用于引导和指示车辆和行人的行驶方向和交通流动。该系统通常由一系列安装在道路或交通设施上的LED显示屏组成，通过显示不同的图案、文字和箭头等信息，向用户提供准确的导航和指引。
                    LED诱导系统可以用于各种交通场景，如高速公路、城市道路、停车场和机场等，具有高亮度、长寿命和可视距离远的特点，能够在各种天气和光照条件下清晰可见，以提供实时的交通信息和路线指示。
                </P>
                <div style="margin-top: .1563rem;">
                    <div style="position: relative;
                    padding-left: .1302rem;
                    font-size: .0729rem;
                    color: #2056c0;">
                        <icon class="el-icon-phone-outline"></icon>技术热线
                    </div>
                    <div class="tel">181&nbsp;&nbsp;2248&nbsp;&nbsp;4161</div>
                </div>
            </div>



        </div>

        <div class="title" style="border-bottom: .0156rem solid #2056c0;width: 6.5104rem ;margin: 0 auto;margin-top: .1042rem; ">
            详细参数
        </div>

        <div class="msgList" style="width: 6.5104rem;margin: 0 auto;">
            <ul>
                <li>
                    <div class="txt"><em>型号</em></div>
                    <div class="msg">LD86XXX</div>
                </li>
                <li>
                    <div class="txt"><em>外壳材料</em></div>
                    <div class="msg">高硬度ABS材料</div>
                </li>
                <li>
                    <div class="txt"><em>规格尺寸</em></div>
                    <div class="msg">110x100x19(mm)</div>
                </li>
                <li>
                    <div class="txt"><em>重量</em></div>
                    <div class="msg">248g</div>
                </li>
                <li>
                    <div class="txt"><em>led玻璃保护层</em></div>
                    <div class="msg">软玻璃弹性保护壳</div>
                </li>
                <li>
                    <div class="txt"><em>led平均发光强度</em></div>
                    <div class="msg">≥6000mcd</div>
                </li>
                <li>
                    <div class="txt"><em>承重</em></div>
                    <div class="msg">≥100KN</div>
                </li>
                <li>
                    <div class="txt"><em>功耗</em></div>
                    <div class="msg">单组6颗LED功耗≤0.45w</div>
                </li>

            </ul>
        </div>






        <div style="display: flex;justify-content: center;margin-top: .1302rem;margin-bottom: .1302rem; ">
            <el-button @click="goBack">返回列表</el-button>
        </div>


    </div>




</template>
<script>


    export default {
        methods: {
            goBack() {
  this.$router.push({ path: '/product-center', query: { tab: 'four' }});
  const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

}
    
        ,

            //       goTo(){
            //     this.$router.push({ path: '/product-center', query: { tab: 'second' } });
            //     const windowHeight = window.innerHeight; // 
            //     const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
            //     const scrollTo = targetOffsetTop - (windowHeight / 2); 
            //     window.scrollTo(0, scrollTo); //
            //   },
        }
    }




</script>

<style scoped>
    .name {
        padding-bottom: .1042rem;
        border-bottom: #eeeeee solid .0052rem;
        font-size: .1042rem;
        color: #2056c0;
        font-weight: bold;
        position: relative;
    }

    .msgList {
        padding-top: .2604rem;

        border-bottom: #eee solid .0052rem;
    }

    .msgList li {
        border: #c6c6c6 solid .0052rem;
        padding: .0521rem .1042rem .0521rem 1.0938rem;
        position: relative;
        font-size: .0729rem;
        margin-bottom: .0521rem;
        text-align: center;
        line-height: .2604rem;
    }

    li {
        list-style-position: outside;
        list-style-type: none;

    }

    .msgList li .msg {
        line-height: .1667rem;
        color: #999;
    }

    .content {
        padding-top: .1563rem;
        font-size: .0729rem;
        line-height: .125rem;
        color: #434343;

    }

    .tel {
        padding-top: .0521rem;
        font-size: .1875rem;
        color: #bb3944;
        font-family: Impact;
    }

    .txt {
        position: absolute;
        left: 0rem;
        top: 0rem;
        width: .9896rem;
        height: 100%;
        text-align: center;
        color: #FFFFFF;
        background: #2056c0;
        font-size: .0729rem;
        font-family: Arial, "微软雅黑";

    }

    .tittle {

        margin-top: .5208rem;
        height: .2083rem;
        text-align: center;
        line-height: .2083rem;
        font-weight: bold;
        font-size: .0833rem;
        color: #fff;

        position: relative;
    }
</style>