function resetRootFZ() {
    var oHtml = document.querySelector('html');
    var w = oHtml.getBoundingClientRect().width; // 获取html节点的宽度

    // 设置根字号的大小等于html节点的宽度的十分之一
    oHtml.style.fontSize = w / 10 + 'px';
}

// 初始化时设置根字号
resetRootFZ();

// 监听窗口大小变化，调用设置根字号的函数
window.addEventListener('resize', function() {
    resetRootFZ();
});