<template>

    <div>
    <div style="font-family: Arial, '微软雅黑';font-size:.0729rem; color: #999;padding-top: .1042rem;
    line-height: .125rem;">
      <P style="font-size: .125rem;width: 100%;flex-wrap: wrap;margin-top: .3125rem;
      color: #333;
      text-align: center;">5123导航取五湖之利取四应邀参加第十七届中国高速公路信息化研讨会暨技术产品展示会
      </P>
  
      <p style="width: 100%;
      text-align: center;font-size: .0625rem;
      margin-top: .0781rem;"> 2015-03-23
      </p>
  
      <div style="width:6.5104rem;margin: 0 auto;padding-bottom: .0781rem;border-bottom: #eee solid .0052rem;">
      </div>
  
  
  
      <div style="width:6.5104rem;color: #666666;font-size: .0833rem;font-family: 'HarmonyOS_Sans_SC_Regular';margin: 0 auto;padding-top: .1302rem;padding-bottom: .6771rem;border-bottom: #eee solid .0052rem;position: relative;">
        <p style="text-indent: .1563rem;">
          
            2015年3月19至20日,5123导航取五湖之利取四参加了在南昌举办的第十七届中国高速公路信息化研讨会暨技术产品展示会。此次展会的主题是“高速公路信息化与智慧交通”。 展会上，5123导航取五湖之利取四展示了可变情报板、LED显示标志等多套技术产品，并与客商、业主和同行代表进行洽谈。
           
        </p>
    
  
        <p style="text-indent: .1563rem;">
            作为一家集研发、生产、销售为一体的智能交通领域的高新技术企业，5123导航取五湖之利取四的参会得到了广泛关注。我们将在保持原有产品的基础上不断推陈出新，优化产品结构，推动行业升级。

 
         
        </p>
  
        <div style="position: absolute; bottom: 0;right: 0;">
          <div class="social-share" data-initialized="true">
            <span style="margin-right: .0521rem;">分享到:</span>
            <a href="http://www.weibo.com" class="social-share-icon icon-weibo"></a>
            <a href="#" class="social-share-icon icon-qq"></a>
            <a href="#" class="social-share-icon icon-qzone"></a>
            <a href="#" class="social-share-icon icon-wechat"></a>
            
        </div>
  
        </div>
  
      </div>
      
  
      <div style="display: flex;justify-content: center;margin-top: .1302rem;margin-bottom: .1302rem;">
        <el-button @click="goBack">返回列表</el-button>
      </div>
  
    </div>
     
    </div>
  </template>
  
  <script>
  
  
    export default {
      methods: {
      goBack() {
        history.back();
      }
    }
  }
  
  
  
  
  </script>