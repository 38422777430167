<template>
    <div>

        <div style="display: flex;justify-content: center;align-items: center;">
            <h1>产品介绍</h1>
            <div v-for="product in products" :key="product.id" class="product">
                <div class="product-image">
                    <canvas :id="'product-canvas-' + product.id"></canvas>
                </div>
                <div class="product-info">
                    <h2 class="product-title">{{ product.title }}</h2>
                    <p class="product-description">{{ product.description }}</p>
                    <a :href="product.link" class="button">了解更多</a>
                </div>
            </div>

        </div>
   
    </div>
</template>

<script>
import * as THREE from 'three';

export default {
    data() {
        return {
            products: [
                {
                    id: 1,
                    title: '产品标题1',
                    description: '产品描述1',
                    link: '#',
                    model: 'product1.glb' // 3D模型文件路径
                },
                {
                    id: 2,
                    title: '产品标题2',
                    description: '产品描述2',
                    link: '#',
                    model: 'product2.glb'
                },
                {
                    id: 3,
                    title: '产品标题3',
                    description: '产品描述3',
                    link: '#',
                    model: 'product3.glb'
                }
            ]
        };
    },
    mounted() {
        this.products.forEach(product => {
            const canvas = document.getElementById(`product-canvas-${product.id}`);
            const renderer = new THREE.WebGLRenderer({ canvas });

            const camera = new THREE.PerspectiveCamera(45, canvas.clientWidth / canvas.clientHeight, 0.1, 1000);
            camera.position.z = 5;

            const scene = new THREE.Scene();

            const loader = new THREE.GLTFLoader();
            loader.load(product.model, gltf => {
                const model = gltf.scene;
                scene.add(model);

                function animate() {
                    requestAnimationFrame(animate);
                    model.rotation.y += 0.01;
                    renderer.render(scene, camera);
                }

                animate();
            });
        });
    }
};
</script>

<style>

</style>